<h5 mat-dialog-title class="fw-bold red-text">Terms and Conditions</h5>
<mat-dialog-content *ngIf="content !== null">
  <p [innerHTML]="content.claimTermsAndConditions.assets.attachedEverything"></p>
  <p [innerHTML]="content.claimTermsAndConditions.assets.trackStatus"></p>
  <p [innerHTML]="content.claimTermsAndConditions.assets.haveAnyQuestion"></p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]="true" color="primary">Accept</button>
  <button mat-raised-button [mat-dialog-close]="false" color="secondary">Cancel</button>
</mat-dialog-actions>
<app-progress-spinner class="example-margin" [displayProgressSpinner]="displayProgressSpinner"></app-progress-spinner>