import {Component, Input} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {GtmEventsService} from '../../services/gtm-events.service';
import {Router} from '@angular/router';
import {ROUTINGCONSTANTS} from '../../constants/routing-constants';
import * as CryptoJS from 'crypto-js';
import {EncrDecrService} from '../../../core/services/encr-decr.service';
import { FeatureToggleService } from '../../services/feature-toggle.service';
import { FullstoryService } from '../../services/fullstory.service';

@Component({
  selector: 'app-new-claim-button',
  templateUrl: './new-claim-button.component.html',
  styleUrls: ['./new-claim-button.component.scss']
})
export class NewClaimButtonComponent {

  public routingConstant = ROUTINGCONSTANTS;
  public submitNewClaimURL = environment.vpiBaseUrl + 'submit-claim';
  @Input() public policyNumber: string;
  @Input() public renewalNumber: string;
  @Input() public focusable;

  constructor(private featureToggleService: FeatureToggleService,
              private gtmService: GtmEventsService,
              private router: Router,
              private fullstoryService: FullstoryService,
              private encDcrService: EncrDecrService) {
  }

  setGTMEvent() {
    const gtmEvents = {
      event: 'Portal Data Layer',
      eventCategory: 'Button Click',
      eventAction: 'Start a New Claim Button',
      eventLabel: 'Navigate to Start a new Claim Button',
      eventValue: `New Claim for ${this.policyNumber}`,
      userId: sessionStorage.getItem('userId')
    };
    this.fullstoryService.recordCustomEvent(gtmEvents.event, { message: gtmEvents });
  }

  fileNewClaim() {
    this.setGTMEvent();
    let _isEnableNewClaimsform = this.featureToggleService.isEnableNewClaimsForm();
    if (_isEnableNewClaimsform) {
      this.router.navigate([this.routingConstant.newClaimsSubmission]);
    }
    else {
      if (this.policyNumber && this.renewalNumber) {
        this.router.navigate([this.routingConstant.fileNewClaim, this.encDcrService.set(this.policyNumber), this.renewalNumber]);
      } else {
        this.router.navigate([this.routingConstant.fileNewClaim]);
      }
    }
  }
}
