<div class="container pb-5">
  <div class="container-title">
    <app-navigation-back id="upperBackButton" (navigateBackEvent)="goToHome()"
      class="pull-left d-inline  pl-1 d-md-none"></app-navigation-back>
    <h1 class="fw-bold">{{ content.accountInfo.header }}</h1>
  </div>
  <hr style="border: 1px solid grey" />

  <div class="row justify-content-center">
    <div class="col-lg-6 col-md-6 col-sm-12">
      <mat-card appearance="outlined" class="custom-mat-card">
        <mat-card-content class="pt-3 pb-3">
          <div class="row justify-content-center">
            <div class="col-lg-10 col-md-10 col-sm-12">
              <app-myaccount [accountInfo]="personalData"></app-myaccount>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <br />
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12">
      <mat-card appearance="outlined" class="custom-mat-card">
        <mat-card-content class="pt-3 pb-3">
          <div class="row justify-content-center">
            <div class="col-lg-10 col-md-10 col-sm-12">
              <app-payment [policyDetailsList]="policyDetailsList" (validPayment)="onValidPayment($event)">
              </app-payment>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <br />

  <div class="row justify-content-center">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <mat-card appearance="outlined">
        <mat-card-content class="pt-3 pb-3">
          <div class="row text-center">
            <div class="col-6 text-start">
              <h5 class="fw-bold">{{ content.accountInfo.managePreferences }}</h5>
            </div>
            <div class="col-6 text-end">
              <a href="javascript:void(0);" [hidden]="!isPreferenceDisabled" (click)="managePreferencesEdit()" class="my-auto edit-text" id="preferenceEdit"><b
                  style="font-size: .8rem;">{{ content.accountInfo.edit }} </b>&nbsp; <i
                  class="far fa-edit fa-lg"></i></a>
            </div>
          </div>
          <hr style="border: 1px solid grey" />

          <div class="row justify-content-center">
            <div class="col-lg-10 col-md-10 col-sm-12">
              <div class="text-start">

                <div *ngIf="!ecpElected" class="row px-3">
                  <div class="float-start mb-3 mt-1">
                    <!-- <img style="width: 70px;" src="../../../assets/images/enrolled-not.gif" class="not-enrolled" -->
                    <img style="width: 70px;" src="{{blueMoneyImage}}" class="not-enrolled"
                      alt="not-enrolled" />
                  </div>
                  <div class="col">
                    <h5 class="ecp">Receive your claim payment electronically!</h5>
                    <p>click <a [href]="routingConstants.electronicClaims"> here</a> to sign up</p>
                  </div>
                </div>

                <div *ngIf="ecpElected" class="row px-3">
                  <div class="float-start mb-3 mt-1">
                    <!-- <img style="width: 70px;" src="../../../assets/images/enrolled.gif" class="not-enrolled" -->
                    <img style="width: 70px;" src="{{blueMoneyImage}}" class="not-enrolled"
                      alt="enrolled" />
                  </div>
                  <div class="col">
                    <h5 class="ecp">You've enrolled to accept claim payments electronically!</h5>
                    <p>click <a [routerLink]="[]" (click)="displayECPCheckbox()"> here</a> to change your preferences.</p>
                  </div>
                </div>

                <div *ngIf="isECPCheckboxDisplayed">
                  <mat-checkbox [checked]="true" [value]="true" (click)="confirmCancelECP($event)" color="primary">
                    {{ content.accountInfo.myAccount.electECP }}
                  </mat-checkbox>
                </div>

                <a href="javascript:void(0);" (click)="navigateToPivot()">
                  <div class="row px-3">
                    <div class="float-start mb-3 mt-1  not-enrolled">
                      <!-- <img src="../../../assets/images/green-leaf2.png" class="paperless-leaf" alt="paperless-leaf" /> -->
                      <img src="{{blueLeafImage}}" class="paperless-leaf" alt="paperless-leaf" />
                    </div>
                    <div class="col my-auto">
                      <h5 class="paperless">
                        Manage your paperless options.
                      </h5>
                    </div>
                  </div>
                </a>
                <div *ngFor="let pref of preferences; let i = index">
                  <mat-checkbox [checked]="pref.response" (change)="setPreference(pref.question)" color="primary" [disabled]="isPreferenceDisabled">
                    <span class="white-space-normal">{{ pref.question }}</span>
                  </mat-checkbox>
                  <div *ngIf="pref.extraInfo" class="ms-4">
                    <a href="javascript:void(0);" (click)="pref.hidden = !pref['hidden']" class="link">What this means</a>
                    <div *ngIf="!pref['hidden']" class="red-border-left pl-1">
                      <p>{{ pref.extraInfo }}</p>
                    </div>
                  </div>
                </div>

                <div class="row col-md-12 pb-2 justify-content-center" [hidden]="isPreferenceDisabled">
                  <div class="pt-2 col-md-3 text-center">
                    <button mat-raised-button type="Cancel" color="primary" id="Cancel" (click)="cancel()" class="custom-account-button mobile-button pref-submit-button button-margin" >{{ content.buttons.cancel }}</button>
                  </div>
                  <div class="pt-2 col-md-3 text-center">
                    <button mat-raised-button type="submit" color="primary" id="save" [disabled]="!canSubmit()" (click)="updateOptIn()" class="custom-account-button mobile-button pref-submit-button" >{{ content.buttons.save }}</button>
                  </div>
                </div>

                <h4 class="mb-1 pt-2">{{ content.accountInfo.privacy.title }}</h4>
                <p>{{ content.accountInfo.privacy.body }}</p>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <br />

  <div class="row justify-content-center">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <mat-card appearance="outlined">
        <mat-card-content class="pt-3 pb-3">
          <div class="row text-center">
            <div class="col-6 text-start">
              <h5 class="fw-bold">{{ content.accountInfo.mySettings }}</h5>
            </div>
            <div class="col-6 text-end">
              <a href="javascript:void(0);" (click)="goToSettings()" class="my-auto edit-text" id="settings"><b
                  style="font-size: .8rem;">{{ content.accountInfo.edit }} </b>&nbsp; <i
                  class="far fa-edit fa-lg"></i></a>
            </div>
          </div>
          <hr style="border: 1px solid grey" />
          <br />
          <p>{{ content.settings.info1 }}</p>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<app-progress-spinner class="example-margin" [displayProgressSpinner]="displayProgressSpinner"> </app-progress-spinner>
