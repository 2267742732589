import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Claim} from '../../models/claim';
import * as _ from 'lodash';
import {ErrorDialogModalComponent} from '../components/error-dialog-modal/error-dialog-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {CONTENT} from '../../content-management/content';
import {ErrorModalData} from '../../models/error-modal-data';
import {ConfirmationDialogModalComponent} from '../components/confirmation-dialog-modal/confirmation-dialog-modal.component';
import {AchConfirmationDialogModalComponent} from '../components/ach-confirmation-dialog-modal/ach-confirmation-dialog-modal.component';
import {AddressValidationModalComponent} from '../components/address-validation-modal/address.validation.modal.component';
import {EnrollpetDialogModalComponent} from '../components/enrollpet-dialog-modal/enrollpet-dialog-modal.component';

import {RecentClaim} from '../../models/recent-claim';
import { TermsDialogModelComponent } from '../components/terms-dialog-model/terms-dialog-model.component';
import { SessionoutDialogModelComponent } from '../components/sessionout-dialog-model/sessionout-dialog-model.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {v4 as uuid} from 'uuid';
import {map} from 'rxjs/operators';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public showSideNavSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showSideNav = this.showSideNavSubject.asObservable();
  public content = CONTENT;
  public setPreviourUrl;
  public clientLoggingUrl = environment.baseUrl + 'it-management/client-logging/v1/client-logs-cloud';
  public addressUrl = environment.baseUrl + 'customer-acquisition/pet-application/v1/address';

  constructor(private dialog: MatDialog, private http: HttpClient, private modalService: NgbModal,) {
  }

  setSideNav(value: boolean) {
    this.showSideNavSubject.next(value);
  }

  public setClaimStatusBar(recentClaims: RecentClaim[]) {
    if (recentClaims) {
      _.forEach(recentClaims, (claim) => {
        this.setProgressBar(claim);
      });
    }
  }

  public setProgressBar(claim) {
    const today = new Date();
    let diff;
    const submittedDate = new Date(claim.reportedDate);
    const timeDiff = Math.abs(submittedDate.getTime() - today.getTime());
    diff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    if (claim.claimStatus === 'Complete') {
      claim.statusbar = 100;
    } else if (claim.claimStatus === 'Received') {
      claim.statusbar = diff >= 15 ? 40 : (diff / 30) * 100;
    } else if (claim.claimStatus === 'InReview' || claim.claimStatus === 'In Process') {
      claim.statusbar = diff <= 15 ? 50 : diff >= 30 ? 90 : (diff / 30) * 100;
    }
  }

  public setProgressBarClaim(claim: Claim) {
    const today = new Date();
    let diff;
    const submittedDate = new Date(claim.reportedDate);
    const timeDiff = Math.abs(submittedDate.getTime() - today.getTime());
    diff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    if (claim.status === 'Complete') {
      claim.statusbar = 100;
    } else if (claim.status === 'Received') {
      claim.statusbar = diff >= 15 ? 40 : (diff / 30) * 100;
    } else if (claim.status === 'InReview' || claim.status === 'In Process') {
      claim.statusbar = diff <= 15 ? 50 : diff >= 30 ? 90 : (diff / 30) * 100;
    }
  }

  public setStatusBar(claim: Claim): Claim {
    if (claim) {
      this.setProgressBarClaim(claim);
    }
    return claim;
  }

  public setDialogModal(dataObject: ErrorModalData) {
   return this.dialog.open(ErrorDialogModalComponent, {
      width: '500px',
      data: dataObject
    });
  }

  public setenrollDialogModal(dataObject: ErrorModalData) {
    this.dialog.open(EnrollpetDialogModalComponent, {
      width: '500px',
      data: dataObject
    });
  }

  public setConfirmationModal(dataObject: ErrorModalData): Observable<any> {
    return this.dialog
      .open(ConfirmationDialogModalComponent, {
        width: '500px',
        data: dataObject
      })
      .afterClosed();
  }

  public setAchConfirmationModal(dataObject: ErrorModalData): Observable<any> {
    return this.dialog
      .open(AchConfirmationDialogModalComponent, {
        width: '500px',
        data: dataObject
      })
      .afterClosed();
  }
  
  public setAddressValidationModal(dataObject: any): Observable<any> {
    return this.dialog.open(AddressValidationModalComponent, {
      width: '600px'  ,
      data: dataObject    
    })
    .afterClosed();
  }  

  public setValidationErrorModal(errorMessage) {
    const content = this.content.validations.registerAccount;
    return this.setDialogModal({
      title: content.error,
      header: errorMessage,
      content: content.errorMessage
    });
  }

  public setNewPetValidationModal(errorMessage) {
    this.setenrollDialogModal({
      title: 'New pet in your pack?',
      header: errorMessage
    });
  }

  public setSessionOutDialogModal(dataObject: ErrorModalData) {
    this.dialog.open(SessionoutDialogModelComponent, {
      width: '500px',
      data: dataObject
    });
  }

  public setTermsDialogModal(dataObject: any) {
    this.dialog.open(TermsDialogModelComponent, {
      width: '800px',
      data: dataObject
    });
  }

  public setSessionOutErrorModal(errorMessage) {
    this.setSessionOutDialogModal({
      title: 'Session Timed Out',
      header: errorMessage,
    });
  }

  public logErrorToSplunk(clientLog_body: any): Observable<any> {
    const contentTypeHeader = new HttpHeaders({
      'X-Nw-Transaction-Id': uuid(),
      client_id: environment.client_id
    });

    return this.http.post(this.clientLoggingUrl, clientLog_body, { headers: contentTypeHeader })
      .pipe(map((response) => response));
  }

  public formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {return '0 Bytes';}

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  verifyAddress(addressData) {
    const httpOptions =  new HttpHeaders({
        client_id: environment.applicationClientId,
        'X-NW-Message-ID': environment.messageId,
      });
    
    return this.http.post<any>(this.addressUrl, addressData, {headers: httpOptions} ).pipe(map(response => response as any));
  }
}
