import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dynamic-terms',
  templateUrl: './dynamic-terms.component.html',
  styleUrls: ['./dynamic-terms.component.scss']
})
export class DynamicTermsComponent implements OnInit {

  public content;
  public displayProgressSpinner = 0;

  constructor() { }

  ngOnInit() {
    this.content = JSON.parse(sessionStorage.getItem('portalContenful'));
  }
}