import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { MyPets } from '../../models/my-pets';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MakeAPayment } from '../../models/make-a-payment';
import { AuthorizePayment } from '../../models/authorize-payment';
import { CancelReasonsResponse } from '../../models/cancel-reasons-response';
import { SSOResponse } from '../../models/response/sso-response';
import { CoverageBenefitsResponse } from '../../models/response/coverage-benefits-response';
import { EncrDecrService } from '../../../../src/app/core/services/encr-decr.service';
import { JwtService } from '../../../../src/app/core/services/jwt.service';
import { EobDocsUrlResponse } from '../../models/response/eob-docs-url-response';

@Injectable({
  providedIn: 'root'
})

export class MyPetsService 
{
  public preFilledFormURL = environment.baseUrl + 'claims-administration/pet-php-claims/v1/';
  public getPendingRenewalStatusURL = environment.baseUrl + 'policy-management/pet-php-policies/v1/';
  public getPastDuesURL = environment.baseUrl + 'policy-management/pet-php-policies/v1/';
  public myPetsURL = environment.baseUrl + 'policy-management/pet-php-policies/v1/';
  public makePaymentURL = environment.baseUrl + 'account-management/pet-php-accounts/v1/';
  public authorizePayments = environment.baseUrl + 'account-management/pet-php-accounts/v1/authorizepayment';
  public policyRenewal = environment.baseUrl + 'policy-management/pet-php-policies/v1/';
  public pivotSsoURL = environment.baseUrl + 'policy-management/pet-php-policies/v1/sso';
  public eobDocsURL = environment.baseUrl + 'policy-management/pet-php-policies/v1/documents';
  public templateBase64: string;
  
  constructor(private http: HttpClient, private encService:EncrDecrService, private jwtService: JwtService) 
  {
  }

  public getPdfPreFilledForm(policyNumber, petName): Observable<any> 
  {

    const petMemberName = sessionStorage.getItem('memberName');
    const i = petMemberName.indexOf(" ");
    const [firstName, lastName] = i !== -1 ? [petMemberName.slice(0,i), petMemberName.slice(i+1)] : [petMemberName, ""];

    let queryParams = new HttpParams().append('req_type', 'XML')
      .append('firstName',firstName)
      .append('lastName', lastName)
      .append('petName', petName);

    return this.http.get(this.preFilledFormURL + 'claims/prefilled-pdf/' + policyNumber,{params: queryParams});
  }
  
  public getPendingRenewalStatus(policyNumber, renewal): Observable<any> {
    const policyInfoList = JSON.parse(sessionStorage.getItem('policyList'));
    const selectedPolicy = policyInfoList?.find(p => p.policyNumber === policyNumber);
    const httpOptions = {
      headers: new HttpHeaders({
        activeMemberId: selectedPolicy.memberId
      })
    };
    return this.http.get(this.getPendingRenewalStatusURL + policyNumber + '/' + renewal + '/' + 'pending-renewal', httpOptions)
      .pipe(map((response) => response));
  }

  public cancelPolicy(policyNumber, cancelRequest, renewalNumber): Observable<any> {
    const policyInfoList = JSON.parse(sessionStorage.getItem('policyList'));
    const selectedPolicy = policyInfoList?.find(p => p.policyNumber === policyNumber);
    const httpOptions = 
    {
      headers: new HttpHeaders({
        activeMemberId: selectedPolicy.memberId
      }),
      body: cancelRequest
    };
    return this.http.request('post', this.policyRenewal + policyNumber + '/' + renewalNumber + '/cancel', httpOptions)
      .pipe(map(policy => policy));
  }

  public cancelReasons(policyNumber): Observable<CancelReasonsResponse> 
  {
    return this.http.request('get', this.policyRenewal +policyNumber+ '/cancelreasons').pipe(
      map(cancelReasonsResponse => 
      {
        return cancelReasonsResponse as CancelReasonsResponse;
      })
    );
  }

  public getAllPets(): Observable<MyPets[]> 
  {
    return this.http.get(this.myPetsURL).pipe(
      map(pets => 
        {
        return pets as MyPets[];
      })
    );
  }

  public getPastDuePayments(policyNumber): Observable<MakeAPayment> 
  {
    return this.http.get(this.getPastDuesURL + policyNumber + '/pastdues').pipe(
      map(dueAmount => 
      {
        return dueAmount as MakeAPayment;
      })
    );
  }

  public authorizePayment(authorizePayments: AuthorizePayment, accountId: string): Observable<AuthorizePayment> 
  {
    const contentTypeHeader = new HttpHeaders({
      activeAccountId: accountId
    });
    return this.http.post(this.authorizePayments, authorizePayments, { headers: contentTypeHeader }).pipe(
      map(payment => payment)
    );
  }

  public getPolicyDocumentsSso(): Observable<SSOResponse> 
  {
    const policyNumber = sessionStorage.getItem("activePolicy");
    const policyInfoList = JSON.parse(sessionStorage.getItem("policyList"));
    const selectedPolicy = policyInfoList?.find(p => p.policyNumber === policyNumber);
    let contentTypeHeader : any;

    if(selectedPolicy.legacyMemberId)
    {
      contentTypeHeader = new HttpHeaders({
        activeMemberId: selectedPolicy.legacyMemberId
      });
    }
    else
    {
      contentTypeHeader = new HttpHeaders({
        activeMemberId: sessionStorage.getItem('activeMemberId')
      });
    }
    return this.http.get(this.pivotSsoURL, { headers: contentTypeHeader }).pipe(
      map(url => url as SSOResponse)
    );
  }

  public getPolicyDocumentsURL(policyNumber: string, claimNumber: string): Observable<EobDocsUrlResponse> 
  {
    let contentTypeHeader = new HttpHeaders({
      activeMemberId: sessionStorage.getItem('activeMemberId'),
    });
    

    let queryParams = new HttpParams().append('req_type', 'XML')
      .append('doclink', 'Y')
      .append('returndoc', 'Y')
      .append('doc_type', 'VPI0100')
      .append('generic_field_4', claimNumber)


      return this.http.get(this.eobDocsURL, { headers: contentTypeHeader, params: queryParams }).pipe(
        map(url => {
          return url as EobDocsUrlResponse;
        })
      );
    // return this.http.get(this.eobDocsURL, { params: queryParams }).pipe(
    //   switchMap(url => {
    //     let docUrlObj = url as EobDocsUrlResponse
    //     return this.getXmlEobDoc(docUrlObj.docUrl);
    //   })
    // );
  }

  public getCoverageLink(): Observable<EobDocsUrlResponse> 
  {
    let contentTypeHeader = new HttpHeaders({
      activeMemberId: sessionStorage.getItem('activeMemberId')
    });
    let policyNumber = sessionStorage.getItem('activePolicy');

    let queryParams = new HttpParams().append('req_type', 'XML')
      .append('doclink', 'Y')
      .append('returndoc', 'Y')
      .append('doc_type', 'VPI0200')
      .append('generic_field_1', policyNumber);
      
      return this.http.get(this.eobDocsURL, { headers: contentTypeHeader, params: queryParams }).pipe(
        map(url => {
          return url as EobDocsUrlResponse;
        })
      );
   
  }

  public getXmlEobDoc(docUrl){
    return this.http.get(docUrl,{responseType: 'blob'});
  }

  public getCoverageBenefitsByPolicy(policyNumber): Observable<CoverageBenefitsResponse> 
  {
    return this.http.get(this.myPetsURL+policyNumber+'/benefits').pipe(
      map(data => {
        return data as CoverageBenefitsResponse;
      })
    );
  }
}