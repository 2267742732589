import {Component, Input, OnInit} from '@angular/core';
import {CONTENT} from '../../../content-management/content';
import {PaymentFormData} from '../../../models/payment-form-data';
import {PaymentInfo} from '../../../models/payment-info';
import {PREFERENCES} from '../../../shared/constants/preferences';
import {Router} from '@angular/router';
import {ROUTINGCONSTANTS} from '../../../shared/constants/routing-constants';
import { CommonService } from '../../../shared/services/common.service';
import {MyPets} from '../../../models/my-pets';
import {EncrDecrService} from '../../../core/services/encr-decr.service';

@Component({
  selector: 'app-my-pet-payment',
  templateUrl: './my-pet-payment.component.html',
  styleUrls: ['./my-pet-payment.component.scss']
})

export class MyPetPaymentComponent implements OnInit 
{
  public content = CONTENT;
  public preferences = PREFERENCES;
  public routes = ROUTINGCONSTANTS;
  public paymentData: PaymentFormData;
  public policyNumber: string;
  public submitted = false;
  public dynamicContent: any;

  @Input() myPet: MyPets;
  @Input() paymentInfo: PaymentInfo;
  @Input() public slideIndex: any;
  @Input() public isMPIPolicy: any;

  constructor(private router: Router,
              private commonService: CommonService,
              private encDcrService: EncrDecrService) {
  }

  ngOnInit(): void 
  {
    this.defaultPetPayment();
  }

  applyPaymentInfo(paymentInfo: PaymentInfo) 
  {
    this.policyNumber = paymentInfo.policyNumber;
    const isCredit = !paymentInfo?.abaNo;
    const paymentDatum = 
    {
      accountNumber: !isCredit && paymentInfo.maskedAcctNo || '',
      accountType: paymentInfo.acctType,
      cardNumber: isCredit && paymentInfo.maskedAcctNo && paymentInfo.maskedAcctNo.toUpperCase() || '',
      expDate: isCredit && paymentInfo.cardExpDate.substring(4, paymentInfo.cardExpDate.length) + '/' + paymentInfo.cardExpDate.substring(0, 4) || '',
      paymentMethod: paymentInfo.payMethod,
      paymentType: isCredit ? (!paymentInfo.maskedAcctNo ? 'Check' : 'Credit card') : 'Debit/Checking account',
      routingNumber: paymentInfo?.abaNo,
      status: 'Current',
      preferences: this.preferences,
      amount: paymentInfo.amount,
      pmtPlan: paymentInfo.pmtPlan
    };
    return paymentDatum;
  }

  defaultPetPayment() 
  {
    if (this.paymentInfo) 
    {
      this.paymentInfo.maskedAcctNo = this.paymentInfo.maskedAcctNo.slice(-10);
      this.paymentData = this.applyPaymentInfo(this.paymentInfo);
    } 
    else 
    {
      setTimeout(() => 
      {
        this.defaultPetPayment();
      }, 250);
    }
  }

  editDetails(): void {
    if (this.myPet?.planCode === "MPPPlus" || (this.myPet?.policyType === "Group Payroll" && this.isNotEmpty(this.myPet?.nextTermMemberId) ? this.myPet?.memberId !== this.myPet?.nextTermMemberId : false)) {
      this.showMPPCChangePayPlanWarningModal();
    } else {
      this.commonService.setPreviourUrl = this.router.url;
      this.router.navigate([this.routes.editPayment, this.encDcrService.set(this.paymentInfo && this.paymentInfo.policyNumber || this.myPet.policyNumber)]);
    }
  }

  showMPPCChangePayPlanWarningModal(){
    this.dynamicContent = JSON.parse(sessionStorage.getItem('portalContenful'));
    this.commonService.setConfirmationModal({
      title: this.dynamicContent?.MPPCChangePayPlanWarningModal?.title ?? '',
      header: this.dynamicContent?.MPPCChangePayPlanWarningModal?.header ?? '',
      content: this.dynamicContent?.MPPCChangePayPlanWarningModal?.bodyContent ?? ''
    });
  }

  isNotEmpty(value: any): boolean {
    return value && value.trim() !== '';
  }

  handleMask(s: string): string 
  {
    if (s) 
    {
      s = s.replace(/\*/g, 'X');
      s = s.toUpperCase();
    } 
    else 
    {
      s = '';
    }
    return s;
  }

  isCredit(): boolean 
  {
    return this.paymentData && this.paymentData.paymentType && (this.paymentData.paymentType.toUpperCase() === 'CREDIT CARD'  ||  this.paymentData.paymentType.toUpperCase() === 'CHECK');
  }

  isCheck():boolean
  {
    return this.paymentData && this.paymentData.paymentType &&  this.paymentData.paymentType.toUpperCase() === 'CHECK';
  }
}
