import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { IBlockHtml } from '../types/contentful';
import { createClient } from 'contentful';
import { Observable } from 'rxjs';

@Injectable()
export class ContentfulService {

  private cdaClient = createClient({
    space: environment.contentful.space,
    environment: environment.contentful.environment,
    accessToken: environment.contentful.accessToken
  });

  constructor() { }

  async getAssetFileUrl(assetID: string): Promise<string> {
    return this.cdaClient.getAsset(assetID).then(res => res.fields.file.url);
  }

  async getAsset(assetID: string): Promise<any> {
    return this.cdaClient.getAsset(assetID).then(res => res.fields);
  }

  async getEntry(entryID: string): Promise<any> {
    return this.cdaClient.getEntry(entryID).then(res => res.fields);
  }

  async getBannerEntry(entryID: string): Promise<any> {
    return this.cdaClient.getEntry(entryID,{include: 10}).then(res => res.fields);
  }

  async getTextBlock(entryID: string): Promise<IBlockHtml> {
    return this.cdaClient.getEntry(entryID).then(res => res as IBlockHtml);
  }

  getCobrandingEntry(cobrandedPartnerName: string): Observable<any>{
    const query = {
      content_type: 'cobrandedPartner',
      'fields.partnerName': cobrandedPartnerName
    }

    return new Observable(observer => {
      this.cdaClient.getEntries(query)
      .then( (entry: any) => {
        const images = {
          policyInfoLogoUrl: entry.includes.Asset[0].fields.file.url,
          cobrandingHeaderLogoUrl: entry.includes.Asset[1].fields.file.url}        
        observer.next(images);
        observer.complete();
      })
      .catch(error =>{
        observer.error(error)
      });
  });
}
}