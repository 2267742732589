import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Location } from '@angular/common';
import { CONTENT } from '../../../content-management/content';
import { IMAGECONSTANTS } from '../../constants/image-constants';
import { HYPERLINKSCONSTANTS } from '../../constants/hyperlinks-constants';
import { Router } from '@angular/router';
import { ROUTINGCONSTANTS } from '../../constants/routing-constants';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { NotificationServiceService } from '../../services/notification-service.service';
import { NOTIFICATIONTYPES } from '../../../models/notification-service-message';
import { environment } from '../../../../environments/environment';
import { MyPetsService } from '../../../modules/my-pets/my-pets.service';
import { BillingPaymentService } from '../../services/billing-payment.service';
import {PolicyDetailsSession} from '../../../models/policy-details-session';
import {DataService} from '../../../core/services/data.service';
import { policySessionContants } from '../../../shared/constants/policy-session-constants';
import { JwtService } from '../../../core/services/jwt.service';
import { FeatureToggleService } from '../../services/feature-toggle.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy, OnChanges {
  public content = CONTENT;
  public imageConstants = IMAGECONSTANTS;
  public hyperLinkConstants = HYPERLINKSCONSTANTS;
  public routingConstants = ROUTINGCONSTANTS;
  public hideCard = false;
  public isExpanded = false;
  public notificationData;
  public myPetsCount: any;
  public interval: any;
  public userId: any;
  public currentUser: any;
  private subscription: Subscription = new Subscription();
  public toggleHelp: boolean;
  public pastDueList: any[] = [];
  public renewalStatusList: any[] = [];
  public expiredList: any[] = [];
  public newList: any[];
  public newPastDueList: any[];
  public newRenewalStatusList: any[];
  public newExpiredList: any[];
  public underwriterURL = environment.vpiBaseUrl + 'Underwriting.aspx';
  public isPetListLoaded = false;

  @Output() menuChange = new EventEmitter();
  @Output() loggedOut = new EventEmitter();
  @Input() showSideNav;
  @Input() mppQuestions;

  constructor(private featureToggleService: FeatureToggleService,
              private _location: Location,
              private router: Router,
              private dataService: DataService,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer,
              private myPetsService: MyPetsService,
              private elementRef: ElementRef,
              private notificationService: NotificationServiceService,
              private billingPaymentService: BillingPaymentService,
              private jwtService: JwtService) {
    this.setSvgIcons();
    this.getUserId();
    this.setNotifications();

  }

  @HostListener('document:click', ['$event'])
  onDocClick(event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.hideCard = false;
    }
  }

  ngOnInit() {
    this.getCurrentUser();
  }

  setNotifications() {
    this.notificationService.getNotificationsUpdate().subscribe(data => {
      if (data.type === NOTIFICATIONTYPES.REMINDMELATER) {
        this.myPetsCount--;
      } else if (data.type === NOTIFICATIONTYPES.UPDATENOTIFICATIONS) {
        this.getCurrentUser();
        this.getPetList();
      }
    });
  }

  getCurrentUser() {
    this.currentUser = sessionStorage.getItem('currentUser');
  }

  getUserId() {
    this.userId = sessionStorage.getItem('userId');
    if (!this.userId) {
      setTimeout(() => {
        this.getUserId();
      }, 250);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.getCurrentUser();
      //if(!changes["mppQuestions"])
      if (this.isPetListLoaded === false) {
        this.isPetListLoaded = true;
        this.getPetList();
      }

    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    clearInterval(this.interval);
  }

  clearPetLists() {
    this.newList = [];
    this.pastDueList = [];
    this.renewalStatusList = [];
    this.expiredList = [];
  }

  getPetList() {
    if (this.userId && this.currentUser) {
      // eslint-disable-next-line import/no-deprecated
      if (window.performance.navigation.type === 1) {
        sessionStorage.removeItem('pastDueList');
        sessionStorage.removeItem('renewalStatusList');
        sessionStorage.removeItem('expiredList');
        sessionStorage.removeItem('newList');
      }

      const notifications: any = [];
      const notificationResponse = {
        notifications: []
      };

      const currentUser = sessionStorage.getItem('currentUser');
      const decodeJwt = this.jwtService.DecodeToken(currentUser);
      const tokenPolicyList = decodeJwt['policyList'];
      this.billingPaymentService.getPolicyDetailsNewList(tokenPolicyList).subscribe(data => {
        if (data) {
          const policyDataList = data.filter(x => x !== undefined);
          if (policyDataList) {
            const responseSessionList: any = [];
            policyDataList.forEach(policyDetail => {
              const poicyDetailSession: PolicyDetailsSession = new PolicyDetailsSession();
              Object.assign(poicyDetailSession, _.pick(policyDetail, policySessionContants.poicyDetailSessionKeys));
              responseSessionList.push(poicyDetailSession);
            });
            sessionStorage.setItem('policyList', JSON.stringify(responseSessionList));
            this.dataService.myPets = policyDataList;
            sessionStorage.setItem('dataService', JSON.stringify(this.dataService));
            const activePolicyList = policyDataList.filter(p => (p.policyStatus.toUpperCase() === "IN FORCE") || (p.policyStatus.toUpperCase() === "UNDER NOTICE") || (p.policyStatus.toUpperCase() === "IN FORCE - FUTURE DATE"));
            this.clearPetLists();
            activePolicyList.forEach(ap => {
              if (ap.renewalStatus && ap.renewalStatus.toUpperCase() === 'RNW') {
                const notification =
                {
                  type: 'policy',
                  status: 'pendingRenewal',
                  key: ap.policyNumber + '/' + ap.renewalNumber
                };
                notifications.push(notification);
              }
            });

            const pastDuePolicies = activePolicyList.filter(ap => ap.pastDue === true);
            pastDuePolicies.forEach(pd => {
              const notification =
              {
                type: 'policy',
                status: 'pastDue',
                key: pd.policyNumber + '/' + pd.renewalNumber
              };
              notifications.push(notification);
            });

            const expiredPolicyList = policyDataList.filter(p => p.policyStatus.toUpperCase() === 'EXPIRED' && p.renewalStatus.toUpperCase() === 'RNW');
            expiredPolicyList.forEach(ep => {
              const notification =
              {
                type: 'policy',
                status: 'expired',
                key: ep.policyNumber + '/' + ep.renewalNumber
              };
              notifications.push(notification);
            });

            notificationResponse.notifications = notifications;
            const myPets = notificationResponse;

            _.forEach(myPets['notifications'], pet => {
              if (pet.status === 'pastDue') {
                this.pastDueList.push(pet);
              }
              if (pet.status === 'pendingRenewal') {
                this.renewalStatusList.push(pet);
              }
              if (pet.status === 'expired') {
                this.expiredList.push(pet);
              }

              this.newPastDueList = this.pastDueList;
              this.newRenewalStatusList = this.renewalStatusList;
              this.newExpiredList = this.expiredList;
            });

            this.myPetsCount = this.pastDueList.length + this.renewalStatusList.length + this.expiredList.length;
            if (this.newRenewalStatusList || this.newPastDueList || this.newExpiredList) {
              const flaggedRenewalList = JSON.parse(JSON.stringify(this.newRenewalStatusList)).map(pet => {
                delete pet['statusFlag'];
                return pet;
              });
              const flaggedPastDueList = JSON.parse(JSON.stringify(this.newPastDueList)).map(pet => {
                pet['statusFlag'] = 'pastDue';
                return pet;
              });

              const flaggedExpiredList = JSON.parse(JSON.stringify(this.newExpiredList)).map(pet => {
                pet['statusFlag'] = 'expired';
                return pet;
              });

              this.newList = [...flaggedPastDueList, ...flaggedRenewalList, ...flaggedExpiredList];
            }

            sessionStorage.setItem('pastDueList', JSON.stringify(this.pastDueList));
            sessionStorage.setItem('renewalStatusList', JSON.stringify(this.renewalStatusList));
            sessionStorage.setItem('expiredList', JSON.stringify(this.expiredList));
            sessionStorage.setItem('newList', JSON.stringify(this.newList));

          }
        }
      });
    } else {
      setTimeout(() => {
        this.getPetList();
      }, 0);
    }
  }

  onEmitHideCard(evt) {
    this.hideCard = evt;
  }

  onEmitUpdatedCount(evt) {
    this.myPetsCount = Number(evt);
  }

  setSvgIcons() {
    const svgIcons = this.imageConstants.svgLogos;
    this.matIconRegistry.addSvgIcon(`login_key`, this.setDomSanitizer(svgIcons.loginKey));
    this.matIconRegistry.addSvgIcon(`home_icon`, this.setDomSanitizer(svgIcons.home));
    this.matIconRegistry.addSvgIcon(`paw_icon`, this.setDomSanitizer(svgIcons.paws));
    this.matIconRegistry.addSvgIcon(`settings_icon`, this.setDomSanitizer(svgIcons.settings));
    this.matIconRegistry.addSvgIcon(`claim_icon`, this.setDomSanitizer(svgIcons.claim));
    this.matIconRegistry.addSvgIcon(`user_icon`, this.setDomSanitizer(svgIcons.user));
    this.matIconRegistry.addSvgIcon(`questionMark_icon`, this.setDomSanitizer(svgIcons.questionMark));
    this.matIconRegistry.addSvgIcon(`notification_icon`, this.setDomSanitizer(svgIcons.notification));
    this.matIconRegistry.addSvgIcon(`arrowLeft_icon`, this.setDomSanitizer(svgIcons.arrowLeft));
    this.matIconRegistry.addSvgIcon(`arrowRight_icon`, this.setDomSanitizer(svgIcons.arrowRight));
    this.matIconRegistry.addSvgIcon(`phone_icon`, this.setDomSanitizer(svgIcons.phone));
    this.matIconRegistry.addSvgIcon(`referAFriend_icon`, this.setDomSanitizer(svgIcons.referAFriend));
    this.matIconRegistry.addSvgIcon(`companion_icon`, this.setDomSanitizer(svgIcons.companion));
    this.matIconRegistry.addSvgIcon(`PHZ_icon`, this.setDomSanitizer(svgIcons.PHZ));
    this.matIconRegistry.addSvgIcon(`cat_icon`, this.setDomSanitizer(svgIcons.catImage));
    this.matIconRegistry.addSvgIcon(`dog_icon`, this.setDomSanitizer(svgIcons.dogImage));
    this.matIconRegistry.addSvgIcon(`attachment_icon`, this.setDomSanitizer(svgIcons.attachment));
    this.matIconRegistry.addSvgIcon(`close_icon`, this.setDomSanitizer(svgIcons.close));
    this.matIconRegistry.addSvgIcon(`footer_home_icon`, this.setDomSanitizer(svgIcons.footerHomeIcon));
    this.matIconRegistry.addSvgIcon(`money_icon`, this.setDomSanitizer(svgIcons.money));
    this.matIconRegistry.addSvgIcon(`call_icon`, this.setDomSanitizer(svgIcons.call));
    this.matIconRegistry.addSvgIcon(`arrowR_icon`, this.setDomSanitizer(svgIcons.arrowR));
    this.matIconRegistry.addSvgIcon(`enroll_icon`, this.setDomSanitizer(svgIcons.enroll));
    this.matIconRegistry.addSvgIcon(`file_icon`, this.setDomSanitizer(svgIcons.fileIcon));
    this.matIconRegistry.addSvgIcon(`file_new_claim_icon`, this.setDomSanitizer(svgIcons.newClaimIcon));
    this.matIconRegistry.addSvgIcon(`pills_icon`, this.setDomSanitizer(svgIcons.pillsIcon));
    this.matIconRegistry.addSvgIcon(`claim_submission_Icon`, this.setDomSanitizer(svgIcons.claimsubnissionIcon));
    
  }

  setDomSanitizer(url: string) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  menuToggle() {
    this.isExpanded = !this.isExpanded;
    this.menuChange.emit(this.isExpanded);
  }

  openLargeNav() {
    document.getElementById('mySidenav').style.width = '30%';
  }

  openSmallNav() {
    document.getElementById('mySidenav').style.width = '100%';
  }

  closeNav() {
    document.getElementById('mySidenav').style.width = '0';
  }

  checkForMenuWidth() {
    if (window.innerWidth <= 768) {
      this.menuToggle();
    }
  }

  goToHome() {
    this.checkForMenuWidth();
    this.navigateToHomePage();
  }

  navigateToHomePage() {
    this.routerNavigate(this.routingConstants.home);
  }

  goToClaims() {
    this.checkForMenuWidth();
    this.routerNavigate(this.routingConstants.claims);
  }

  goToPets() {
    this.checkForMenuWidth();
    this.routerNavigate(this.routingConstants.pets);
  }

  
  goToSupportingDocuments() {
    this.checkForMenuWidth();
    this.routerNavigate(this.routingConstants.claimSubmission);
  }

  goToSettings() {
    this.checkForMenuWidth();
    this.routerNavigate(this.routingConstants.settings);
  }

  goToViewDocuments() {
    this.myPetsService.getPolicyDocumentsSso().subscribe(url => {
      if (url.ssoUrl) {
        if (window.innerWidth <= 768) {
          window.open(url.ssoUrl, '_parent');
        } else {
          window.open(url.ssoUrl, '_blank');
        }
      }
    });
  }

  goToNotifications() {
    if (this.myPetsCount > 0) {
      if (window.innerWidth <= 768) {
        if (this.router.url === '/notifications') {
          this._location.back();
        } else {
          this.notificationData = this.router.navigate([this.routingConstants.notifications]);
        }
        this.isExpanded = false;
      } else {
        this.hideCard = !this.hideCard;
      }
    }
  }

  goToMyAccount() {
    this.checkForMenuWidth();
    this.routerNavigate(this.routingConstants.login);
    this.router.navigate([this.routingConstants.account]);
  }

  goToStartANewClaimPage() {
    this.checkForMenuWidth();
    this.router.navigate([this.routingConstants.newClaimsSubmission]);
  }

  helpToggle() {
    this.helpMenuToggle();
    this.toggleHelp = !this.toggleHelp;
  }

  helpMenuToggle() {
    this.isExpanded = true;
    this.menuChange.emit(this.isExpanded);
  }

  logout() {
    this.isExpanded = false;
    this.loggedOut.emit();
  }

  routerNavigate(routeTo) {
    this.router.navigate([routeTo]);
  }

  onClickHideCard() {
    this.hideCard = false;
  }
}
