<div class="container pb-5">
  <div class="container-title">
    <app-navigation-back id="upperBackButton" (navigateBackEvent)="goToAccount()" class="pull-left d-inline  pl-1 d-md-none"></app-navigation-back>
    <h1 class="fw-bold" id="edit-account-header">{{ content.accountInfo.myAccount.header }}</h1>
  </div>
  <hr style="border: 1px solid grey"/>
  <form [formGroup]="editPersonalInfoForm" *ngIf="editPersonalInfoForm" (ngSubmit)="save()">
    <div class="row justify-content-center">
      <div class="col-lg-12 col-md-12 sol-sm-12">
        <mat-card appearance="outlined">
          <mat-card-content class="pt-3 pb-3">
            <div class="row justify-content-center">
              <div class="col-lg-5 col-md-5 col-sm-12">
                <mat-form-field class="material-input-full-width mat-form-field-readonly" appearance="outline">
                  <mat-icon matPrefix>account_circle</mat-icon>
                  <input matInput readonly formControlName="firstName" id="firstName" [(ngModel)]="editPersonalInfoForm.value.firstName" type="text" class="readonly-input"/>
                  <mat-label
                  ><b>{{ labels.firstName }}</b></mat-label
                  >
                </mat-form-field>
              </div>
              <div class="col-lg-5 col-md-5 col-sm-12">
                <mat-form-field class="material-input-full-width mat-form-field-readonly" appearance="outline">
                  <mat-icon matPrefix>account_circle</mat-icon>
                  <input matInput readonly formControlName="lastName" id="lastName" [(ngModel)]="editPersonalInfoForm.value.lastName" type="text" class="readonly-input"/>
                  <mat-label
                  ><b>{{ labels.lastName }}</b></mat-label
                  >
                </mat-form-field>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-10 col-md-10 col-sm-12">
                <mat-form-field class="material-input-full-width" appearance="outline">
                  <mat-icon matPrefix>local_shipping</mat-icon>
                  <input matInput formControlName="addressLine1" id="addressLine1" [(ngModel)]="editPersonalInfoForm.value.addressLine1" type="text"
                  #focusAddr1 maxlength="{{ formValidation.lengthValidation.addressLine1Length }}" [placeholder]="placeHolders.addressLine1" required/>
                  <mat-label
                  ><b>{{ labels.addressLine1 }}</b></mat-label
                  >
                  <mat-error
                    *ngIf="editPersonalInfoForm.controls.addressLine1.errors && editPersonalInfoForm.controls.addressLine1.errors.required"> {{ errorMessages.addressLine1.required }}</mat-error>
                  <mat-error
                    *ngIf="editPersonalInfoForm.controls.addressLine1.errors && editPersonalInfoForm.controls.addressLine1.errors.pattern"> {{ errorMessages.addressLine1.pattern }}</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-10 col-md-10 col-sm-12">
                <mat-form-field class="material-input-full-width" appearance="outline">
                  <mat-icon matPrefix>local_shipping</mat-icon>
                  <input matInput formControlName="addressLine2" id="addressLine2" [(ngModel)]="editPersonalInfoForm.value.addressLine2" type="text"
                  maxlength="{{ formValidation.lengthValidation.addressLine2Length }}" />
                  <mat-label
                  ><b>{{ labels.addressLine2 }}</b></mat-label
                  >
                  <mat-error
                    *ngIf="editPersonalInfoForm.controls.addressLine2.errors && editPersonalInfoForm.controls.addressLine2.errors.pattern"> {{ errorMessages.addressLine2.pattern }}</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row" *ngIf="isPendingRenewal">
              <p class="mx-auto col-12 col-md-10 error-text">
                {{ errorMessages.pendingRenewalState.initial }}
              </p>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-4 col-md-4 col-sm-12">
                <mat-form-field class="material-input-full-width" appearance="outline">
                  <mat-icon matPrefix>location_city</mat-icon>
                  <input matInput formControlName="city" id="city" [(ngModel)]="editPersonalInfoForm.value.city" type="text" (keypress)="alphabetsOnly($event)" [placeholder]="placeHolders.city"
                         required/>
                  <mat-label
                  ><b>{{ labels.city }}</b></mat-label
                  >
                  <mat-error *ngIf="editPersonalInfoForm.controls.city.errors && editPersonalInfoForm.controls.city.errors.required"> {{ errorMessages.city.required }}</mat-error>
                  <mat-error *ngIf="editPersonalInfoForm.controls.city.errors && editPersonalInfoForm.controls.city.errors.pattern"> {{ errorMessages.city.pattern }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-12">
                <mat-form-field class="material-input-full-width" appearance="outline">
                  <mat-label>{{ labels.state }}</mat-label>
                  <mat-icon matPrefix>local_shipping</mat-icon>
                  <mat-select formControlName="state" id="state" required>
                    <mat-option value="">{{ defaults.state }}</mat-option>
                    <mat-option *ngFor="let state of stateCodes" [value]="state">{{ state }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="editPersonalInfoForm.controls.state.errors && editPersonalInfoForm.controls.state.errors.pattern"> {{ errorMessages.state.pattern }}</mat-error>
                  <mat-error *ngIf="editPersonalInfoForm.controls.state.errors && editPersonalInfoForm.controls.state.errors.required"> {{ errorMessages.state.required }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-12">
                <mat-form-field class="material-input-full-width" appearance="outline">
                  <mat-icon matPrefix>local_shipping</mat-icon>
                  <input matInput formControlName="zipCode" id="zipCode" [(ngModel)]="editPersonalInfoForm.value.zipCode" type="tel" maxlength="{{ formValidation.lengthValidation.zipCodeMaxLength }}"
                         (keypress)="numbersOnly($event)" mask="00000" [placeholder]="placeHolders.zipCode" required/>
                  <mat-label
                  ><b>{{ labels.zipCode }}</b></mat-label
                  >
                  <mat-error *ngIf="editPersonalInfoForm.controls.zipCode.errors && editPersonalInfoForm.controls.zipCode.errors.pattern"> {{ errorMessages.zipCode.pattern }}</mat-error>
                  <mat-error *ngIf="editPersonalInfoForm.controls.zipCode.errors && editPersonalInfoForm.controls.zipCode.errors.required"> {{ errorMessages.zipCode.required }}</mat-error>
                  <mat-error *ngIf="editPersonalInfoForm.controls.zipCode.errors && editPersonalInfoForm.controls.zipCode.errors.maxlength"> {{ errorMessages.zipCode.maxLength }}</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-10 col-md-10 col-sm-12">
                <mat-form-field class="material-input-full-width" appearance="outline">
                  <mat-icon matPrefix>email</mat-icon>
                  <input matInput formControlName="emailAddress" id="emailAddress" [(ngModel)]="editPersonalInfoForm.value.emailAddress" type="text"
                         [maxlength]="formValidation.lengthValidation.emailLength" [placeholder]="placeHolders.emailAddress" required/>
                  <mat-label
                  ><b>{{ labels.emailAddress }}</b></mat-label
                  >
                  <mat-error
                    *ngIf="editPersonalInfoForm.controls.emailAddress.errors && editPersonalInfoForm.controls.emailAddress.errors.pattern"> {{ errorMessages.emailAddress.pattern }}</mat-error>
                  <mat-error
                    *ngIf="editPersonalInfoForm.controls.emailAddress.errors && editPersonalInfoForm.controls.emailAddress.errors.required"> {{ errorMessages.emailAddress.required }}</mat-error>
                  <mat-error
                    *ngIf="editPersonalInfoForm.controls.emailAddress.errors && editPersonalInfoForm.controls.emailAddress.errors.maxlength"> {{ errorMessages.emailAddress.maxLength }}</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-5 col-md-5 col-sm-12">
                <mat-form-field class="material-input-full-width" appearance="outline">
                  <mat-icon matPrefix>call</mat-icon>
                  <input matInput formControlName="homePhone" id="homePhone" [(ngModel)]="editPersonalInfoForm.value.homePhone" maxlength="12" type="tel" mask="000-000-0000"
                         [placeholder]="placeHolders.homePhone" required/>
                  <mat-label><b>{{ labels.homePhone }}</b></mat-label>
                  <mat-error *ngIf="editPersonalInfoForm.controls.homePhone.errors && editPersonalInfoForm.controls.homePhone.errors.minlength"> {{ errorMessages.phone.pattern }}</mat-error>
                  <mat-error *ngIf="editPersonalInfoForm.controls.homePhone.errors && editPersonalInfoForm.controls.homePhone.errors.required"> {{ errorMessages.phone.required }}</mat-error>
                  <mat-error *ngIf="editPersonalInfoForm.controls.homePhone.errors && editPersonalInfoForm.controls.homePhone.errors.maxlength"> {{ errorMessages.phone.maxLength }}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-5 col-md-5 col-sm-12">
                <!--                <mat-form-field class="material-input-full-width" appearance="outline">-->
                <!--                  <mat-icon matPrefix>call</mat-icon>-->
                <!--                  <input matInput formControlName="mobilePhone" id="mobilePhone" [(ngModel)]="editPersonalInfoForm.value.mobilePhone" type="tel" mask="000-000-0000" [placeholder]="placeHolders.mobilePhone" />-->
                <!--                  <mat-label>-->
                <!--                    <b>{{ labels.mobilePhone }}</b>-->
                <!--                  </mat-label>-->
                <!--                  <mat-error *ngIf="editPersonalInfoForm.controls.mobilePhone.errors && editPersonalInfoForm.controls.mobilePhone.errors.minlength"> {{ errorMessages.phone.pattern }}</mat-error>-->
                <!--                  <mat-error *ngIf="editPersonalInfoForm.controls.mobilePhone.errors && editPersonalInfoForm.controls.mobilePhone.errors.maxlength"> {{ errorMessages.phone.maxLength }}</mat-error>-->
                <!--                </mat-form-field>-->
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <br/>

        <div class="container-title">
          <h5 class="fw-bold" id="edit-manage-preferences">{{ content.accountInfo.managePreferences }}</h5>
        </div>
        <hr style="border: 1px solid grey"/>

        <mat-card appearance="outlined">
          <mat-card-content>
            <div class="row justify-content-center">
              <div class="col-lg-10 col-md-10 col-sm-12">
                <div *ngIf="!originalECP" class="row px-3">
                  <div class="float-start mb-3 mt-1">
                    <img src="../../../assets/images/enrolled-not.gif" class="not-enrolled" alt="not-enrolled"/>
                  </div>
                  <div class="col">
                    <h5 class="ecp">Receive your claim payment electronically!</h5>
                    <p>click <a [href]="routes.electronicClaims"> here</a> to sign up</p>
                  </div>
                </div>
                <a href="javascript:void(0);" (click)="navigateToPivot()">
                  <div class="row px-3">
                    <div class="float-start mb-3 mt-1 text-center not-enrolled">
                      <img src="../../../assets/images/green-leaf2.png" class="paperless-leaf" alt="paperless-leaf"/>
                    </div>
                    <div class="col my-auto">
                      <h5 class="paperless" id="paperless">
                        Manage your paperless options.
                      </h5>
                    </div>
                  </div>
                </a>
                <div *ngFor="let pref of preferences; let i = index">
                  <!-- {{pref.question}} -->
                  <mat-checkbox [checked]="pref.response" (change)="setPreference(pref.question)" color="primary" id="promotional-info-{{ i }}">
                    <span class="white-space-normal">{{ pref.question }}</span>
                  </mat-checkbox>
                  <div *ngIf="pref.extraInfo" class="ml-4">
                     <!-- eslint-disable-next-line @angular-eslint/template/click-events-have-key-events -->
                    <p (click)="pref.hidden = !pref['hidden']" class="link">What this means</p>
                    <div *ngIf="!pref['hidden']" class="red-border-left pl-1">
                      <p>{{ pref.extraInfo }}</p>
                    </div>
                  </div>
                </div>
                <mat-checkbox formControlName="electECP" *ngIf="originalECP" color="primary" id="electEcp">
                  {{ content.accountInfo.myAccount.electECP }}
                </mat-checkbox>
                <h4 class="mb-1">{{ content.accountInfo.privacy.title }}</h4>
                <p>{{ content.accountInfo.privacy.body }}</p>
              </div>
            </div>
            <br/>
            <div class="row justify-content-center pb-2">
              <div class="col-lg-5 col-md-5 col-sm-12 text-center pt-2">
                <button mat-raised-button type="button" color="primary" id="cancel" class="custom-account-button mobile-button" (click)="cancel()">{{ content.buttons.cancel }}</button>
              </div>
              <div class="col-lg-5 col-md-5 col-sm-12 text-center pt-2">
                <button mat-raised-button type="submit" color="primary" id="save" class="custom-account-button mobile-button" [disabled]="!canSubmit()">{{ content.buttons.save }}</button>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </form>
</div>

<app-progress-spinner class="example-margin" [displayProgressSpinner]="displayProgressSpinner"></app-progress-spinner>
