import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ValidatePolicyRequest} from '../../models/request/validate-policy-request';
import {ABAValidationResponse} from '../../models/aba-validation-response';
import {Observable, of} from 'rxjs';
import {forkJoin} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {PolicyDetailsResponse} from '../../models/response/policy-details-response';
import {PaymentInfo} from '../../models/payment-info';
import {PolicyDetailResponse} from '../../models/response/policy-detail-response';
import {UpdatePaymentResponse} from '../../models/response/update-payment-response';
import {EditPaymentMidtermRequest} from '../../models/request/edit-payment-midterm-request';
import {EditPaymentResponse} from '../../models/response/edit-payment-response';
import {EditPaymentInfoResponse} from '../../models/response/edit-payment-info-response';
import {EditPaymentRequest} from '../../models/request/edit-payment-request';
import {CardProfileResponse} from '../../models/response/card-profile-response';

@Injectable({
  providedIn: 'root'
})
export class BillingPaymentService {
  public policiesUrl = environment.baseUrl + 'policy-management/pet-php-policies/v1/';
  public renPoliciesUrl = environment.renbaseUrl + 'policies/v1/';
  public abaValidationUrl = environment.orchBaseUrl + 'ABAValidation/?';
  public cardProfileUrl = environment.cardProfileUrl;
  public organizationUrl = environment.orchBaseUrl + 'organizationDetails?';
  public accountsUrl = environment.accountsUrl + 'account';
  public paymentHistoryUrl = environment.baseUrl + 'account-management/pet-php-accounts/v1/';
  public httpOptions: any;
  acctNo: any;


  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'client_id': environment.paymentGatewayClientId,
        'X-NW-Message-ID': environment.messageId,
      })
    };
  }

  updatePaymentInfo(policyInfo: { paymentInfoList: PaymentInfo[] }, accountId: string): Observable<UpdatePaymentResponse> {
    const contentTypeHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      activeAccountId: accountId
    });
    return this.http.post<UpdatePaymentResponse>(this.policiesUrl + 'renew', policyInfo, { headers: contentTypeHeader }).pipe(map(response => response));
  }

  updatePaymentDetails(policyInfo: { paymentInfoList: PaymentInfo[] }): Observable<any> {
    const policyInfoList = JSON.parse(sessionStorage.getItem('policyList'));
    const uniqueAccountIds: any[] = [];
    const updatePaymentInfoList: any = [];
    policyInfoList.forEach(element => {
      if (!uniqueAccountIds.some(x => x == element.accountId) && policyInfo.paymentInfoList.some(x => x.policyNumber == element.policyNumber)) {
        uniqueAccountIds.push(element.accountId);
        updatePaymentInfoList.push(this.updatePaymentInfo(policyInfo, element.accountId));
      }

    });
    return forkJoin(updatePaymentInfoList);
  }

  validateABA(abaNumber: string): Observable<ABAValidationResponse> {
    return this.http.get<ABAValidationResponse>(this.abaValidationUrl + 'ABANumber=' + abaNumber).pipe(
      map(validation => validation)
    );
  }

  getPolicyDetails(policyNumber: string, renewal: string): Observable<PolicyDetailResponse> {
    const policyInfoList = JSON.parse(sessionStorage.getItem('policyList'));
    const selectedPolicy = policyInfoList?.find(p => p.policyNumber === policyNumber);
    const contentTypeHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      activeMemberId: selectedPolicy.memberId,
      activeAccountId: selectedPolicy.accountId
    });
    return this.http.get<PolicyDetailResponse>(`${this.policiesUrl}${policyNumber}/${renewal}`, { headers: contentTypeHeader }).pipe(
      map(response => response)
    );
  }

  getPaymentInfo(policyNumber: string, renewal: number) {
    const policyInfoList = JSON.parse(sessionStorage.getItem('policyList'));
    const selectedPolicy = policyInfoList?.find(p => p.policyNumber === policyNumber);

    const contentTypeHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      activeAccountId: selectedPolicy.accountId
    });
    return this.http.get(this.policiesUrl + policyNumber + '/' + renewal + '/billing', { headers: contentTypeHeader });
  }

  getAllPaymentInfo(activeAccountId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        activeAccountId
      })
    };
    return this.http.get(this.policiesUrl + 'billing',httpOptions);
  }

  getPaymentInfoList(): Observable<any>{
    const policyInfoList = JSON.parse(sessionStorage.getItem('policyList'));
    const uniqueAccountIds: any[] = [];
    const paymentInfoList: any = [];
    policyInfoList.forEach(element => {
      if (!uniqueAccountIds.some(x => x == element.accountId)) {
        uniqueAccountIds.push(element.accountId);
        paymentInfoList.push(this.getAllPaymentInfo(element.accountId));
      }

    });
    return forkJoin(paymentInfoList);
  }

  renewalPolicy(policyNumber: string, policyInfo: ValidatePolicyRequest) {
    const policyInfoList = JSON.parse(sessionStorage.getItem('policyList'));
    const selectedPolicy = policyInfoList?.find(p => p.policyNumber === policyNumber);

    const contentTypeHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      activeAccountId: selectedPolicy.accountId
    });
    return this.http.post(this.policiesUrl + policyNumber + '/authorizerenewal', policyInfo, { headers: contentTypeHeader });
  }

  renewalPayments(policyNumber: string, PolicyMode: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get(this.policiesUrl + policyNumber + '/' + PolicyMode + '/renewalpayments', { headers });
  }

  getPolicyDetailsNewList(tokenPolicyList): Observable<any> {
    const responseList: any=[];
    tokenPolicyList.forEach(element => {
      responseList.push(this.getPolicyDetail(element.policyNumber));
    });
    return forkJoin(responseList);
  }

  getPolicyDetail(policyNumber: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get(this.policiesUrl + policyNumber, { headers }).pipe(
      map(data => {
        const responseData: any = data;
        if(responseData && responseData.memberId && responseData.accountId){
          return responseData;
        }else{
          return undefined;
        }
      }),
      catchError(e => of(undefined))
    );
  }


  getPolicyDetailsList(): Observable<PolicyDetailsResponse> {
    return this.http.get<PolicyDetailsResponse>(this.policiesUrl).pipe(map(polityDetailsResponse => polityDetailsResponse as PolicyDetailsResponse));
  }

  getRenPolicyDetailsList(): Observable<PolicyDetailsResponse> {
    return this.http.get<PolicyDetailsResponse>(this.renPoliciesUrl).pipe(map(polityDetailsResponse => polityDetailsResponse as PolicyDetailsResponse));
  }

  cardProfiles(request: any): Observable<CardProfileResponse> {
    const lastFour = request.accountNumber;    
    const cardLength = lastFour && lastFour.length;
    if (cardLength) {
      this.acctNo = lastFour.slice(cardLength - 4, cardLength);
    } 

    return this.http.post<CardProfileResponse>(this.cardProfileUrl, request, this.httpOptions).pipe(
      map(response => {
        if (response != null) {
          response['accountNumber'] = this.acctNo;
        }
        return response as CardProfileResponse;
      }));
  }

  editPaymentMidterm(request: EditPaymentMidtermRequest): Observable<EditPaymentResponse> {
    return this.http.post(this.renPoliciesUrl + 'midterm', request).pipe(map(response => response as EditPaymentResponse));
  }

  editPaymentRenewal(request: EditPaymentMidtermRequest): Observable<EditPaymentResponse> {
    return this.http.post(this.renPoliciesUrl + 'renewal', request).pipe(map(response => response as EditPaymentResponse));
  }

  editPaymentInfo(request: EditPaymentRequest): Observable<EditPaymentInfoResponse> {
    return this.http.post(this.renPoliciesUrl + 'endorsement', request).pipe(map(response => response as EditPaymentInfoResponse));
  }

  editPolicyPaymentInfo(request: EditPaymentRequest): Observable<EditPaymentInfoResponse> {
    return this.http.post(this.renPoliciesUrl + 'policy-endorsement', request).pipe(map(response => response as EditPaymentInfoResponse));
  }

  getOrganizationDetails(payrollId, nonPayrollId): Observable<any> {
    // Check the nonPayrollId later
    return this.http.get<string>(this.organizationUrl + 'payrollId=' + payrollId + '&nonPayrollId=' + nonPayrollId);
  }

  getCompanyDetail(company: any): Observable<any> {
    return this.http.post(this.accountsUrl, company);
  }

  getPaymentHistoy(policyNumber: string) {
    const policyInfoList = JSON.parse(sessionStorage.getItem('policyList'));
    const selectedPolicy = policyInfoList?.find(p => p.policyNumber === policyNumber);

    const contentTypeHeader = new HttpHeaders({
      activeAccountId: selectedPolicy.accountId
    });
    return this.http.get(this.paymentHistoryUrl + policyNumber + '/paymenthistory', { headers: contentTypeHeader });
  }
}
