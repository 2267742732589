import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import { SplunkLogRequest } from '../../models/splunk-log-request';
import { SplunkLogEvent } from '../../models/splunk-log-event';
import { SplunkLogLog } from '../../models/splunk-log-log';
import {CommonService} from '../../shared/services/common.service';

const CLAIM_FIELDS = {
  hospitalClinicName: 'hospitalClinicName',
  claimedConditions: 'claimedConditions',
  invoicesCount: 'totalInvoices',
  totalClaimSubmitted: 'totalClaimSubmitted',
  deductibleAmount: 'deductibleAmount',
  treatmentDateFrom: 'treatmentDateFrom',
  treatmentDateTo: 'treatmentDateTo',
  filename: 'fileObj'
};

@Injectable({
  providedIn: 'root'
})
export class FileNewClaimService {
  //public submitNewClaimURL = environment.renbaseUrl + 'claims/v1/';
  public submitNewClaimURL = environment.baseUrl + 'claims-administration/pet-php-claims/v1/';
  public fileList: any[] =[];

  constructor(private http: HttpClient,private commonService: CommonService) {
  }

  // upload(policyNumber, renewalNumber, formData): Observable<any> {
  //   return this.http.post(this.submitNewClaimURL + policyNumber + '/' + renewalNumber + '/' + 'upload-files', formData)
  //     .pipe(map(response => {
  //         return response;
  //       })
  //     );
  // }

  upload(policyNumber, renewalNumber, formData): Observable<any> {
    this.splunkLogforRequest(policyNumber, renewalNumber, formData);
    const startDate = (new Date(formData.get("treatmentDateFrom"))).toISOString().split('T')[0].replace(/-/g, '');
    const endDate = (new Date(formData.get("treatmentDateTo"))).toISOString().split('T')[0].replace(/-/g, '');
        
    return this.http.post(this.submitNewClaimURL + 'claims/' + policyNumber + '/' + renewalNumber +'?treatmentDateFrom='+ startDate +'&treatmentDateTo='+ endDate, formData)
      .pipe(map(response => {
          return response;
        })
      );
  }

  splunkLogforRequest(policyNumber, renewalNumber, formData){
    const event = new SplunkLogEvent();
    event.app = 'pet_nmp';
    event.level = 'INFO';
    event.env = environment.env;
    event.message = 'File a new Claim - Request Log';
    event.url = this.submitNewClaimURL + 'claims/' + policyNumber + '/' + renewalNumber;

    const outputObj: any={};
    const fileObj: string[]=[];
    this.fileList = [];    
    // Iterate over each form data entry
    for (const [key, value] of formData.entries()) {
      // Check if the value is a file (form data might contain files)
      if (value instanceof File) {
        fileObj.push(value.name + ': ' + this.commonService.formatBytes(value.size));
        this.fileList.push(value.name);
      } else {
        // Map form fields to outputObj dynamically based on key names
        if (key === "hospitalClinicName") {
          outputObj.hospitalClinicName = value;
        } else if (key === "claimedConditions") {
          outputObj.claimedConditions = value;
        } else if (key === "invoicesCount") {
          outputObj.invoicesCount = value;
        } else if (key === "totalClaimSubmitted") {
          outputObj.totalClaimSubmitted = value;
        } else if (key === "deductibleAmount") {
          outputObj.deductibleAmount = value;
        } else if (key === "treatmentDateFrom") {
          outputObj.treatmentDateFrom = value;
        } else if (key === "treatmentDateTo") {
          outputObj.treatmentDateTo = value;
        }
      }
    }
    
    outputObj.filename = fileObj;
    setTimeout(() => {
    event.payLoad = JSON.stringify(outputObj);
    const d = new Date();
    event.requestTimeStamp=d.toString();

    const log = new SplunkLogLog();
    log.event = JSON.stringify(event);
    log.fields = '{}';
    log.sourceType = 'nmp_json';

    const payLoad = new SplunkLogRequest();
    payLoad.splunkToken = environment.splunkToken;
    payLoad.logs = [];
    payLoad.logs.push(log);

    this.commonService.logErrorToSplunk(payLoad).subscribe(x => { });
      
    }, 500);

  }
}
