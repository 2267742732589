<header class="align-self-end pt-2 fixed-top header-line header-zindex">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-3 col-md-3 col-sm-2 col-2 pt-2 d-block d-md-none">
        <a href="javascript:void(0);" *ngIf="showSideNav" (click)="menuToggle()" class="menubutton">
          <mat-icon class="custom_menu">menu</mat-icon>
        </a>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-8 col-8 main--logo mb-2">
        <a href="javascript:void(0);" id="nationwide-logo" (click)="navigateToHomePage()"><img width="200" alt="Nationwide logo" src="{{ imageConstants.nationwideLogo }}"/></a>
      </div>
      <div class="col-lg-6 col-md-6 col-2  text-right">
        <div class="header-right">
          <!--<p class="no_bottom_margin fw-bold d-none d-md-block">{{ content.header.contact.phone }}</p>-->
          <p class="d-none d-md-block">
            <a href="{{ underwriterURL }}" title="Underwriting" target="_blank">{{ content.header.contact.underwriter }}</a>
          </p>
          <mat-nav-list class="material-icons d-block d-md-none m-notification">
            <mat-list-item (click)="goToNotifications()">
              <mat-icon
                matListItemIcon
                matBadge="{{ myPetsCount }}"
                *ngIf="currentUser"
                [matBadgeHidden]="myPetsCount === 0"
                matBadgePosition="above before"
                matbadgeOverlap="true"
                matBadgeColor="warn"
                svgIcon="notification_icon"
                matTooltip="{{ content.header.hamburgerMenu.notifications }}"
                matTooltipPosition="right"
                [matTooltipDisabled]="isExpanded"
                matTooltipClass="custom-tooltip"
              >
              </mat-icon>
            </mat-list-item>
          </mat-nav-list>
        </div>
      </div>
    </div>
  </div>
</header>
<mat-drawer *ngIf="showSideNav" #drawer mode="side" disableClose="true" opened="true" [ngClass]="{active: isExpanded, 'hide-nav-bar': !isExpanded}">
  <div class="custom_header pt-2 pb-2">
    <div class="col-10 text-left">
      <a href="javascript:void(0);" (click)="goToHome()"><img width="140" alt="nationwideLogo" id="nationwide.logo" src="{{ imageConstants.nationwideLogo }}"/></a>
    </div>
    <div class="col-2 text-right ">
      <a href="javascript:void(0)" class="close-nav" (click)="menuToggle()">
        <!-- <mat-icon>close</mat-icon> -->
        <!-- <img src="../../../../assets/images/svg-icons/nw_portal_icon_150px_close.svg" alt=""> -->
        <mat-icon svgIcon="close_icon"></mat-icon>
      </a>
    </div>
  </div>
  <mat-nav-list [ngClass]="!isExpanded?'menulist-width':''">
    <a mat-list-item (click)="goToHome()" tabindex="0" href="javascript:void(0);" aria-label="Home">
      <mat-icon matListItemIcon svgIcon="home_icon" matTooltip="{{ content.header.hamburgerMenu.home }}" matTooltipPosition="right" [matTooltipDisabled]="isExpanded"
                matTooltipClass="custom-tooltip"></mat-icon>
      <h4 matListItemLine *ngIf="isExpanded">
        <b>{{ content.header.hamburgerMenu.home }}</b>
      </h4>
    </a>
    <a mat-list-item (click)="goToClaims()" href="javascript:void(0);" tabindex="0" aria-label="My Claims">
      <mat-icon matListItemIcon svgIcon="claim_icon" matTooltip="{{ content.header.hamburgerMenu.claims }}" matTooltipPosition="right" [matTooltipDisabled]="isExpanded"
                matTooltipClass="custom-tooltip"></mat-icon>
      <h4 matListItemLine *ngIf="isExpanded">
        <b>{{ content.header.hamburgerMenu.claims }}</b>
      </h4>
    </a>
    <a mat-list-item (click)="goToPets()" tabindex="0" href="javascript:void(0);" aria-label="My pets">
      <mat-icon matListItemIcon svgIcon="paw_icon" matTooltip="{{ content.header.hamburgerMenu.pets }}" matTooltipPosition="right" [matTooltipDisabled]="isExpanded"
                matTooltipClass="custom-tooltip"></mat-icon>
      <h4 matListItemLine *ngIf="isExpanded">
        <b>{{ content.header.hamburgerMenu.pets }}</b>
      </h4>
    </a>

    <a mat-list-item (click)="goToSupportingDocuments()" tabindex="0" href="javascript:void(0);" aria-label="Supporting Documents">
      <mat-icon matListItemIcon svgIcon="claim_submission_Icon" matTooltip="{{ content.header.hamburgerMenu.claimSubmission }}" matTooltipPosition="right" [matTooltipDisabled]="isExpanded"
                matTooltipClass="custom-tooltip"></mat-icon>
      <h4 matListItemLine *ngIf="isExpanded">
        <b>{{ content.header.hamburgerMenu.claimSubmission }}</b>
      </h4>
    </a>
    <a mat-list-item (click)="goToMyAccount()" tabindex="0" href="javascript:void(0);" aria-label="My account">
      <mat-icon matListItemIcon svgIcon="user_icon" matTooltip="{{ content.header.hamburgerMenu.info }}" matTooltipPosition="right" [matTooltipDisabled]="isExpanded"
                matTooltipClass="custom-tooltip"></mat-icon>
      <h4 matListItemLine *ngIf="isExpanded">
        <b>{{ content.header.hamburgerMenu.info }}</b>
      </h4>
    </a>
    <a mat-list-item (click)="goToStartANewClaimPage()" tabindex="0" href="javascript:void(0);" aria-label="Start a new claim">
      <mat-icon matListItemIcon  matTooltip="{{ content.header.hamburgerMenu.newClaim }}" matTooltipPosition="right" [matTooltipDisabled]="isExpanded"
                matTooltipClass="custom-tooltip">note_add</mat-icon>
      <h4 matListItemLine *ngIf="isExpanded">
        <b>{{ content.header.hamburgerMenu.newClaim }}</b>
      </h4>
    </a>
    <a mat-list-item (click)="goToViewDocuments()" tabindex="0" href="javascript:void(0);" aria-label="View my documents">
      <mat-icon matListItemIcon  matTooltip="{{ content.header.hamburgerMenu.viewDocuments }}" matTooltipPosition="right" [matTooltipDisabled]="isExpanded"
                matTooltipClass="custom-tooltip">folder
      </mat-icon>
      <h4 matListItemLine *ngIf="isExpanded">
        <b>{{ content.header.hamburgerMenu.viewDocuments }}</b>
      </h4>
    </a>
    <a mat-list-item (click)="goToSettings()" tabindex="0" href="javascript:void(0);" aria-label="Settings">
      <mat-icon matListItemIcon svgIcon="settings_icon" matTooltip="{{ content.header.hamburgerMenu.settings }}" matTooltipPosition="right" [matTooltipDisabled]="isExpanded"
                matTooltipClass="custom-tooltip"></mat-icon>
      <h4 matListItemLine *ngIf="isExpanded">
        <b>{{ content.header.hamburgerMenu.settings }}</b>
      </h4>
    </a>
    <a mat-list-item tabindex="0" (click)="helpToggle()" href="javascript:void(0);" aria-label="Help">
      <mat-icon matListItemIcon svgIcon="questionMark_icon" matTooltip="{{ content.header.hamburgerMenu.help }}" matTooltipPosition="right" [matTooltipDisabled]="isExpanded"
                matTooltipClass="custom-tooltip"></mat-icon>
      <h4 matListItemLine  class="text-left" *ngIf="isExpanded">
        <b>{{ content.header.hamburgerMenu.help }}</b>
      </h4>
      <mat-icon matListItemIcon class="arrowDown" *ngIf="isExpanded">keyboard_arrow_down</mat-icon>
    </a>

    <div *ngIf="toggleHelp"  attr.aria-expanded="{{ isExpanded ? true : false}}">
      <div class="sub-menu" *ngIf="isExpanded">
        <a href="{{ hyperLinkConstants.header.help.phone }}" class="sub-menu">844-397-8938</a>
        <a href="{{ hyperLinkConstants.header.help.email }}" target="_blank" id="email" class="sub-menu">E-mail</a>
        <a href="{{ hyperLinkConstants.header.help.faq }}" target="_blank" id="faq" class="sub-menu">FAQ</a>
      </div>
    </div>
    <a mat-list-item (click)="goToNotifications()" [attr.aria-disabled]="hideCard ? 'true' : 'false'" class="mobile-hide" tabindex="0" href="javascript:void(0);" aria-label="Notifications"  >
      <mat-icon
        matListItemIcon
        matBadge="{{ myPetsCount }}"
        [matBadgeHidden]="myPetsCount === 0"
        matBadgePosition="above before"
        matbadgeOverlap="true"
        matBadgeColor="warn"
        svgIcon="notification_icon"
        matTooltip="{{ content.header.hamburgerMenu.notifications }}"
        matTooltipPosition="right"
        [matTooltipDisabled]="isExpanded"
        matTooltipClass="custom-tooltip"
      >
      </mat-icon>
      <h4 matListItemLine class="text-left" *ngIf="isExpanded" >
        <b>{{ content.header.hamburgerMenu.notifications }}</b>
      </h4>
      <!-- </mat-list-item> -->
      <mat-card appearance="outlined" class="notifications-card" [attr.aria-hidden]="hideCard ? 'true' : 'false'" *ngIf="hideCard" (click)="$event.stopPropagation()" [cdkTrapFocus]="true">
        <mat-card-actions>
          <button class="mat-action-button" (click)="onClickHideCard()">X</button>
        </mat-card-actions>
        <mat-card-content>
          <app-notifications (hideCard)="onEmitHideCard($event)" (updatedCount)="onEmitUpdatedCount($event)" [isFromPopover]="true"></app-notifications>
        </mat-card-content>
      </mat-card>
    </a>

    <a mat-list-item (click)="logout()" tabindex="0" href="javascript:void(0);" aria-label="Logout">
      <mat-icon matListItemIcon svgIcon="login_key" matTooltip="{{ content.header.hamburgerMenu.logout }}" matTooltipPosition="right" [matTooltipDisabled]="isExpanded"
                matTooltipClass="custom-tooltip"></mat-icon>
      <h4 matListItemLine *ngIf="isExpanded">
        <b>{{ content.header.hamburgerMenu.logout }}</b>
      </h4>
    </a>
    <a mat-list-item (click)="menuToggle()" class="expand mobile-hide" tabindex="0" href="javascript:void(0);" aria-label="Collapse navigation ">
      <mat-icon *ngIf="!isExpanded" matListItemIcon svgIcon="arrowRight_icon" matTooltip="{{ content.header.hamburgerMenu.expand }}" matTooltipPosition="right"
                matTooltipClass="custom-tooltip"></mat-icon>
      <mat-icon *ngIf="isExpanded" matListItemIcon svgIcon="arrowLeft_icon"></mat-icon>
      <h4 matListItemLine class="text-left" *ngIf="isExpanded">
        <b>{{ content.header.hamburgerMenu.collapse }}</b>
      </h4>
    </a>
  </mat-nav-list>
</mat-drawer>
