import { Injectable } from '@angular/core';
import { FullStory, init as initFullStory } from '@fullstory/browser';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FullstoryService {

  private readonly fullStoryOrgId = environment.fullStoryOrgId;
  
  constructor() {
    const isProduction = !environment.production;
    initFullStory({
      orgId: this.fullStoryOrgId,
      devMode: isProduction,
    });
  }

  recordCustomEvent(eventName: string, eventProperties: any) {
    FullStory('trackEvent', { name: eventName, properties: eventProperties })
  }

  logUserIdentity(username:string, displayName:string, email:string) {
    FullStory('setIdentity', {
      uid: username,
      properties: {
        displayName: displayName,
        email: email
      }
    });
  }
}
