import {jsPDF} from 'jspdf';

export class PetIdPdf {
  petName: string;
  policyNumber: string;
  coverage: string;
  policyExpirationDate: string;
  status: string;
  memberName: string;
  relationShip: string;
  insuranceDob: string;
  bin: string;
  pcn: string;
  group: string;
  petRxExpressLogoUrl:string;
  headerLogoUrl: string;

  constructor(params: {
    petName: string;
    policyNumber: string;
    coverage: string;
    policyExpirationDate: string;
    status: string;
    memberName: string;
    relationShip: string;
    insuranceDob: string;
    bin: string;
    pcn: string;
    group: string;
    effectiveUntil: string;
    petRxExpressLogoUrl:string;
    headerLogoUrl:string;
  }) {
    this.petName = params.petName;
    this.policyNumber = params.policyNumber;
    this.coverage = params.coverage;
    this.policyExpirationDate = params.policyExpirationDate;
    this.status = params.status;
    this.memberName = params.memberName;
    this.relationShip = params.relationShip;
    this.insuranceDob = params.insuranceDob;
    this.bin = params.bin;
    this.pcn = params.pcn;
    this.group = params.group;
    this.petRxExpressLogoUrl = params.petRxExpressLogoUrl;
    this.headerLogoUrl = params.headerLogoUrl
  }

  createPdf(): jsPDF {
    // preview here http://raw.githack.com/MrRio/jsPDF/master/
    const doc = new jsPDF("p", "mm", "letter");
    const splitCoverageText = doc.splitTextToSize(this.coverage, 120);
    let extraPadding = 0;
    if (splitCoverageText.length === 2) {
      extraPadding = 5;
    }
    if (splitCoverageText.length === 3) {
      extraPadding = 7;
    }
    doc.setDrawColor(0, 114, 207);
    doc.setFillColor(255, 255, 255);
    doc.roundedRect(50, 30, 110, 145 + extraPadding, 5, 5, 'F');
    doc.roundedRect(50, 30, 110, 145 + extraPadding, 5, 5, 'S');
    doc.setFillColor(0, 114, 207);
    doc.rect(50, 35, 110, 12, 'F');
    doc.roundedRect(50, 30, 110, 10 + extraPadding, 5, 5, 'F');

    doc.addImage(this.petRxExpressLogoUrl, 'JPEG', 55, 93 + extraPadding, 50, 4,"marketingLogo");
    doc.addImage(this.headerLogoUrl, 'JPEG', 80, 30 , 45, 17, "headerLogo");
    
    
    // draw dividers
    doc.setDrawColor(118, 118, 118); 
    doc.line(55, 62, 155, 62);
    doc.line(55, 90 + extraPadding, 155, 90 + extraPadding);
    // doc.line(55, 110, 145, 110);
    doc.line(55, 138 + extraPadding, 155, 138 + extraPadding);

    // text
    // header
    doc.setFont('helvetica', 'bold');
    doc.setTextColor(0, 114, 207);
    doc.setFontSize(15);
    doc.text('Pet Insurance ID Card', 75, 57);

    // fields
    doc.setFont('helvetica', 'normal');
    doc.setTextColor(118, 118, 118);
    doc.setFontSize(10);
    doc.text('Pet Name:', 55, 68);
    doc.text('Policy #/Member ID:', 55, 73);
    doc.text('Coverage: ', 55, 78);
    // doc.text('Policy Expiration Date: ', 55, 83 + extraPadding);
    doc.text('Status: ', 55, 83 + extraPadding);

    doc.setFont('helvetica', 'bold');
    doc.setTextColor(0, 114, 207);
    doc.setFontSize(13);
    doc.text('Pharmacy ID Information', 55, 102 + extraPadding);

    doc.setFont('helvetica', 'normal');
    doc.setTextColor(118, 118, 118);
    doc.setFontSize(10);
    doc.text('Member Name: ', 55, 107 + extraPadding);
    doc.text('Relationship: ', 55, 112 + extraPadding);
    doc.text('Insurance DOB*: ', 55, 117 + extraPadding);
    doc.text('BIN: ', 55, 122 + extraPadding);
    doc.text('PCN: ', 55, 127 + extraPadding);
    doc.text('GROUP: ', 55, 132 + extraPadding);


    // legal
    doc.setFont('helvetica', 'bold');
    doc.text('*This date is displayed for pharmacy record-keeping', 55, 145 + extraPadding);
    doc.text('only and may not exactly reflect your pet\'s age.', 55, 150 + extraPadding);
    doc.setFont('helvetica', 'normal');
    doc.setFont('helvetica', 'italic');
    doc.text('This card is valid only at participating pharmacies.', 55, 155 + extraPadding);
    doc.text('Nationwide pet insurance benefits are available for use', 55, 160 + extraPadding);
    doc.text('at any pharmacy and veterinary practice of your choice.', 55, 165 + extraPadding);

    // values
    doc.setFontSize(10);
    doc.setFont('helvetica', 'bold');
    doc.text(this.petName, 72, 68);
    doc.text(this.policyNumber, 88, 73);
    doc.text(splitCoverageText, 73, 78);
    // doc.text(this.policyExpirationDate, 92, 83 + extraPadding);
    doc.text(this.status, 67, 83 + extraPadding);

    doc.text(this.memberName, 81, 107 + extraPadding);
    doc.text(this.relationShip, 78, 112 + extraPadding);
    doc.text(this.insuranceDob, 82, 117 + extraPadding);
    doc.text(this.bin, 63, 122 + extraPadding);
    doc.text(this.pcn, 64, 127 + extraPadding);
    doc.text(this.group, 70, 132 + extraPadding);
    return doc;
  }

  save() {
    this.createPdf().save(`${this.petName}-pet-insurance-id-card.pdf`);
  }

  print() {
    const pdf = this.createPdf();
    pdf.autoPrint();
    window.open(pdf.output('bloburl').toString(), '_blank');
  }

}
