import { BillingPaymentService } from '../../../shared/services/billing-payment.service';
import { CARDPATTERNS } from '../../../shared/constants/card-patterns';
import { CommonService } from '../../../shared/services/common.service';
import { ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { CONTENT } from '../../../content-management/content';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormValidationConstants } from '../../../shared/constants/form-validation-constants';
import { MatSelectChange } from '@angular/material/select';
import { PaymentFormData } from '../../../models/payment-form-data';
import { PaymentInfo } from '../../../models/payment-info';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTINGCONSTANTS } from '../../../shared/constants/routing-constants';
import { ACHCONSTANTS } from '../../../shared/constants/ach-constants';
import * as _ from 'lodash';
import * as xml2js from 'xml2js';
import moment from 'moment';
import { OTEDocumentService } from '../../../shared/services/ote-document.service';
import { PolicyDetails } from '../../../models/policy-details';
import { provideNgxMask } from 'ngx-mask';
import { Location } from '@angular/common';
import { AccountService } from '../../../shared/services/account.service';
import { AccountInfo } from '../../../models/account-info';
import { GtmEventsService } from '../../../shared/services/gtm-events.service';
import { EncrDecrService } from '../../../core/services/encr-decr.service';
import { EditPaymentValidation } from './edit-payment-validation';
import { EditPaymentMidtermRequest } from '../../../models/request/edit-payment-midterm-request';
import { EditPaymentResponse } from '../../../models/response/edit-payment-response';
import { EditPaymentRequest } from '../../../models/request/edit-payment-request';
import { EditPaymentInfoResponse } from '../../../models/response/edit-payment-info-response';
import { JwtService } from '../../../core/services/jwt.service';
import { FullstoryService } from '../../../shared/services/fullstory.service';
import { finalize } from 'rxjs';


@Component({
  selector: 'app-edit-payment',
  templateUrl: './edit-payment.component.html',
  styleUrls: ['./edit-payment.component.scss'],
  providers: [provideNgxMask()]
})
export class EditPaymentComponent implements OnInit {
  public accountTypes = [{ display: 'Checking', value: 'Checking' }, { display: 'Savings', value: 'Savings' }];
  public cardTypes = ['American Express', 'Discover', 'MasterCard', 'Visa'];
  public cardPatterns = CARDPATTERNS;
  public content = CONTENT;
  public editPaymentInfoForm: UntypedFormGroup;
  public errorMessages = CONTENT.accountInfo.payment.errorMessages;
  public formValidation = FormValidationConstants;
  public labels = CONTENT.accountInfo.payment.labels;
  public months = [];
  public paymentTypes = ['Credit card', 'Bank account'];
  public placeholders = CONTENT.accountInfo.payment.placeholders;
  public routes = ROUTINGCONSTANTS;
  public achConstants = ACHCONSTANTS;
  public error: string;
  public expirationYears: number[];
  public paymentData: PaymentFormData;
  public paymentInfo: PaymentInfo;
  public policyNumber: string[];
  public userId: string;
  public isPolicySelected = false;
  public paymentList: PaymentInfo[] = [];
  public policyDetailsList: PolicyDetails[] = [];
  public policyDetailsNewList = [];
  public policyNumberFromRoute = false;
  public prevPolicySelected: string[];
  public hideCC = true;
  public displayProgressSpinner = 0;
  public hideABA = true;
  public hideAcctNo = true;
  public isCancelled = false;
  public isExpired = false;
  public accountInfo: AccountInfo;
  public isGroupPayroll = true;
  public isPaymentListRetrieved = false;
  public isPolicyListRetrieved = false;
  public editPaymentMidtermRequest: EditPaymentMidtermRequest;
  public payMethod: string;
  public renewalNumber: string;
  public editPaymentResponse: any = <EditPaymentResponse>{};
  public editPaymentInfoRequest: EditPaymentRequest;
  public editPaymentInforResponse: EditPaymentInfoResponse;
  public isTermsCondition = false;
  public applyFormDataforPR: any;
  public installmentFee: string;
  public renewalBillingFlag: boolean = false;
  public midTermRenewalFlag: boolean = false;
  public billingFlag: boolean = false;
  public isPolicyPayroll:boolean;
  policyType: string;
  renewalStatus: string;
  showConfirmation = false;
  editPaymentInfo: EditPaymentRequest;
  maskedAcctNo: string;
  paymentType: string;
  acctNo: string;
  abaNo: string;
  payType: boolean;
  policyExpiryDate: Date;
  installmentPlan = '';
  installmentAmount: number = null;
  empName: any;
  policyStatus: boolean;
  payrollId: string;
  nonPayrollId: string;
  totalDue: any;
  renewalMidterm = false;
  payMethodCode: string;
  installment: number;
  renewalEffectiveDate: Date;
  pedFlag = false;
  dateFlag = false;
  dateFlagForPIF: boolean;
  boxPifPayment: number;
  lastFour: any;
  abaError: boolean;
  modalContent: string;
  termIdentifier: string;
  policyInformation: any;
  isLoadedWithGP: boolean;
  public isPriorPastDue = false;
  @ViewChildren('myCheckbox') private myCheckboxes: QueryList<any>;


  constructor(private cdr: ChangeDetectorRef,
    private accountService: AccountService,
    private fb: UntypedFormBuilder,
    private location: Location,
    private oteDocumentService: OTEDocumentService,
    private billingPaymentService: BillingPaymentService,
    private router: Router,
    private gtmService: GtmEventsService,
    private encDcrService: EncrDecrService,
    private commonService: CommonService,
    private jwtService: JwtService,
    private fullstoryService: FullstoryService,
    private route: ActivatedRoute) {
    this.getMonths();
    route.params.subscribe(params => {
      const tempPolicyNumber = this.encDcrService.get(params['policyNumber']);
      if (tempPolicyNumber) {
        this.policyNumberFromRoute = true;
        this.policyNumber = [tempPolicyNumber];
        this.isPolicySelected = true;
      }
    });
  }

  ngOnInit() {
    this.addSpinner();
    this.getPolicyInfoList();
    this.addSpinner();
    this.getPaymentsList();
    this.addSpinner();
    this.defaultPetPayment();
    this.addSpinner();
    this.getAccountInfo();
    this.renewalStatus = sessionStorage.getItem('renewalStatus');
    this.removeSpinner();
  }

  getAccountInfo() {
    this.addSpinner();
    var memberId = sessionStorage.getItem("activeMemberId");
    this.accountService.getAccountInfo(memberId).pipe(finalize(() => this.removeSpinner()))
    .subscribe({
      next: ({ accountInfoResponse }) => {
        this.accountInfo = {
          ...accountInfoResponse,
          homePhone: (accountInfoResponse.homePhone || '').replace(/[^0-9+]/g, ''),
          workPhone: (accountInfoResponse.workPhone || '').replace(/[^0-9+]/g, '')
        };
      },
      complete: () => this.removeSpinner()
    });
  }

  getPolicy(policyNumber: string): PolicyDetails {
    return this.policyDetailsList.reduce((acc, curr) => (curr.policyNumber === policyNumber ? curr : acc), null);
  }

  fieldClick(s: string) {
    const myCheckboxes = this.myCheckboxes.toArray();
    if (s === 'cardNumber') {
      this.editPaymentInfoForm.controls.cardNumber.reset();
      this.editPaymentInfoForm.controls.cardNumber.setValue('');
    } else if (s === 'routingNumber') {
      this.editPaymentInfoForm.controls.routingNumber.reset();
      this.editPaymentInfoForm.controls.routingNumber.setValue('');
    } else if (s === 'accountNumber') {
      this.editPaymentInfoForm.controls.accountNumber.reset();
      this.editPaymentInfoForm.controls.accountNumber.setValue('');
    }
    myCheckboxes[0]._checked = false;
    this.isTermsCondition = false;
  }

  applyPaymentInfo(paymentInfo: PaymentInfo) {
    this.paymentInfo = paymentInfo;
    const isCredit = this.paymentInfo.payMethod.toUpperCase() === 'CC'

    return {
      accountNumber: (!isCredit && paymentInfo.maskedAcctNo) || '',
      accountType: paymentInfo.acctType,
      cardNumber: (isCredit && paymentInfo.maskedAcctNo) || '',
      expDate: (isCredit && paymentInfo.cardExpDate.substring(4, paymentInfo.cardExpDate.length) + '/' + paymentInfo.cardExpDate.substring(0, 4)) || '',
      paymentMethod: paymentInfo.payMethod,
      paymentType: isCredit ? 'Credit card' : 'Bank account',
      routingNumber: paymentInfo?.abaNo
    };
  }

  addCreditInfo(paymentInfo: PaymentInfo): PaymentInfo {
    const cardNumber: string = this.editPaymentInfoForm.controls.cardNumber.dirty ? this.editPaymentInfoForm.value.cardNumber : this.paymentData.cardNumber;
    paymentInfo.acctNo = cardNumber;
    paymentInfo.maskedAcctNo = cardNumber.substr(cardNumber.length - 4);
    const monthString = this.editPaymentInfoForm.value.expMonth < 10 ? '0' + this.editPaymentInfoForm.value.expMonth.toString() : this.editPaymentInfoForm.value.expMonth;
    paymentInfo.cardExpDate = this.editPaymentInfoForm.value.expYear + monthString;
    paymentInfo.payMethod = 'CC';
    paymentInfo.abaNo = '';
    paymentInfo.acctType = this.getCardType(this.editPaymentInfoForm.value.cardNumber);
    return paymentInfo;
  }

  addBankInfo(paymentInfo: PaymentInfo): PaymentInfo {
    const accountNumber: string = this.editPaymentInfoForm.controls.accountNumber.dirty ? this.editPaymentInfoForm.value.accountNumber : this.paymentData.accountNumber;
    paymentInfo.abaNo = this.editPaymentInfoForm?.controls?.routingNumber?.dirty ? this.editPaymentInfoForm?.value?.routingNumber : this.paymentData?.routingNumber;
    paymentInfo.acctNo = accountNumber;
    paymentInfo.maskedAcctNo = accountNumber.substr(accountNumber.length - 4);
    paymentInfo.payMethod = 'ACH';
    paymentInfo.cardExpDate = '';
    paymentInfo.acctType = this.editPaymentInfoForm.value.accountType;
    if (this.editPaymentInfoForm.value.accountType == 'SAVINGS') {
      paymentInfo.acctType = 'SAVING';
    }
    return paymentInfo;
  }

  applyFormData(): {
    paymentInfoList: PaymentInfo[];
  } {
    this.policyNumber = this.editPaymentInfoForm.value.policyNumber;
    let newPaymentInfo: PaymentInfo = this.paymentInfo && JSON.parse(JSON.stringify(this.paymentInfo)) || {};
    newPaymentInfo.userName = this.userId;
    const paymentType: string = this.editPaymentInfoForm.value.paymentType;
    if (paymentType) {
      if (paymentType.toUpperCase() === 'CREDIT CARD') {
        newPaymentInfo = this.addCreditInfo(newPaymentInfo);
      } else {
        newPaymentInfo = this.addBankInfo(newPaymentInfo);
      }
    }
    delete newPaymentInfo['policyNumber'];

    const currentPaymentInfos = this.getPolicyNumbers()
      .split(',')
      .map(policyNumber => {
        const paymentInfo = this.paymentList && this.paymentList.reduce((acc, curr) => policyNumber === curr.policyNumber ? curr : acc, null) || {};

        const newPayment: PaymentInfo = {
          ...this.accountInfo,
          homeTel: this.accountInfo.homePhone,
          bizTel: this.accountInfo.workPhone,
          zip: this.accountInfo.zipCode,
          ...paymentInfo,
          payMethod: newPaymentInfo.payMethod,
          acctNo: newPaymentInfo.acctNo,
          acctType: newPaymentInfo.acctType,
          cardExpDate: newPaymentInfo.cardExpDate,
          securityCode: newPaymentInfo.securityCode,
          maskedAcctNo: newPaymentInfo.maskedAcctNo,
          abaNo: newPaymentInfo?.abaNo,
          accountId: sessionStorage.getItem('activeAccountId')
        };
        return newPayment;
      });
    return {
      paymentInfoList: currentPaymentInfos
    };
  }

  getPolicyNumbers(): string {
    if (this.policyNumber) {
      if (this.isAllSelected(this.policyNumber)) {
        return this.policyDetailsList.reduce((acc, payment) => acc ? `${acc},${payment.policyNumber}` : payment.policyNumber, '');
      }
      return this.policyNumber.toString();
    }
    return '';
  }

  getMonths() {
    for (let i = 1; i <= 12; i++) {
      this.months.push(i);
    }
  }

  isTermsConditionChange(event) {
    if (event.checked) {
      this.isTermsCondition = true;
    } else {
      this.isTermsCondition = false;
    }
  }

  canSubmit(): boolean {
    return this.isTermsCondition && this.editPaymentInfoForm.valid && this.editPaymentInfoForm.dirty;
  }

  goBack() {
    this.router.navigateByUrl(this.commonService.setPreviourUrl);
  }

  removeAll(policies: string[]) {
    return policies.filter(policy => policy !== 'all');
  }

  handleAll(policies: string[]) {
    const allSelectedPreviously = this.prevPolicySelected && this.isAllSelected(this.prevPolicySelected);
    const isAllSelected = this.isAllSelected(policies);
    if (isAllSelected) {
      if (!allSelectedPreviously) {
        this.policyNumber = ['all'];
        return true;
      }
      this.policyNumber = this.removeAll(this.policyNumber);
    }
    return false;
  }

  changePayment(e: MatSelectChange) {
    if (e.value) {
      this.isPolicySelected = true;
      this.policyNumber = e.value;
      if (this.policyNumber && this.policyNumber.length) {
        const payment = this.getPaymentFromPaymentList(this.policyNumber);
        if (payment !== null) {
          this.paymentData = this.applyPaymentInfo(payment);
        }
        const isAllSelected = this.handleAll(this.policyNumber);
        if (isAllSelected || this.policyNumber.length > 1) {
          this.allPetsForm();
        } else {
          this.createForm();
        }
      } else {
        this.allPetsForm();
        this.isPolicySelected = false;
      }
      this.prevPolicySelected = JSON.parse(JSON.stringify(this.policyNumber));
    }
  }

  allPetsForm() {
    this.paymentData = {
      accountNumber: '',
      accountType: '',
      cardNumber: '',
      expDate: '',
      paymentMethod: '',
      paymentType: '',
      routingNumber: ''
    };
    this.createForm();
  }

  cCLast4(s: string) {
    return (s && '' + s.substr(s.length - 4)) || '';
  }

  abaLast4(s: string) {
    return (s && '' + s.substr(s.length - 4)) || '';
  }

  acctNoLast4(s: string) {
    return (s && '' + s.substr(s.length - 4)) || '';
  }

  getLast4(s: string) {
    let last4 = '';
    if (s) {
      if (s.length > 4) {
        last4 = s.slice(s.length - 4);
      } else {
        last4 = s;
      }
    }
    return last4;
  }

  createForm(): void {
    this.addSpinner();
    const policy = this.policyNumber && this.policyNumber.length === 1 && this.getPolicy(this.policyNumber[0]) || null;
    this.isGroupPayroll = policy && policy.policyType === 'Group Payroll';
    this.editPaymentInfoForm = this.fb.group(
      {
        policyNumber: [(this.isPolicySelected && this.policyNumber) || ''],
        accountType: [(this.isPolicySelected && this.paymentData && this.paymentData.accountType) || ''],
        accountNumber: [
          this.isPolicySelected && this.paymentData && this.paymentData.accountNumber ? `***************${this.getLast4(this.paymentData.accountNumber)}` : '',
          [Validators.maxLength(this.formValidation.lengthValidation.accountMaxLength), Validators.pattern(this.formValidation.patternValidation.accountRouting)]
        ],
        cardNumber: [
          this.isPolicySelected && this.paymentData && this.getLast4(this.paymentData.cardNumber) ? `000000000000${this.getLast4(this.paymentData.cardNumber)}` : '',
          [Validators.pattern(this.formValidation.patternValidation.creditCard), Validators.minLength(this.formValidation.lengthValidation.ccMinLength), EditPaymentValidation.cardTypeValidator]
        ],
        expMonth: [(this.isPolicySelected && this.paymentData && this.getMonthFromDate(this.paymentData.expDate)) || ''],
        expYear: [(this.isPolicySelected && this.paymentData && this.paymentData.expDate.split('/')[1]) || ''],
        paymentType: [this.isPolicySelected && this.paymentData && this.paymentData.paymentType || this.isGroupPayroll && 'Credit card' || '', Validators.required],
        routingNumber: [this.paymentData && this.getLast4(this.paymentData.routingNumber) ? `00000${this.getLast4(this.paymentData.routingNumber)}` : '',
           [Validators.maxLength(this.formValidation.lengthValidation.routingLength), Validators.pattern(this.formValidation.patternValidation.accountRouting)]],
        isGroupPayroll: [this.isGroupPayroll],
        stillEmployed: [true],
        frequency: ['monthly'],
      },
      { validator: this.creditValidators }
    );
    this.expirationYears = this.getNext10Years();

    this.editPaymentInfoForm.valueChanges.subscribe({
      next: () => {
        if (this.editPaymentInfoForm.dirty) {
          this.cdr.detectChanges();
        }
      },
      complete:() => this.removeSpinner() 
    });
    this.removeSpinner();
  }

  creditValidators(fg: UntypedFormGroup): any {
    let errors = {};
    if (fg.get('paymentType').value === 'Credit card') { // credit selected
      errors = EditPaymentValidation.creditValidation(fg, errors);
    } else { // ach selected
      errors = EditPaymentValidation.achValidation(fg, errors);
    }
    const empty = _.isEmpty(errors);
    return empty ? null : errors;
  }

  defaultPetPayment() {
    this.addSpinner();
    if (this.isPaymentListRetrieved && this.isPolicyListRetrieved) {
      if (this.policyNumber) {
        const payment = this.paymentList && this.paymentList.reduce((acc, curr) => curr && curr.policyNumber === this.policyNumber[0] ? curr : acc, null);
        if (payment) {
          this.paymentData = this.applyPaymentInfo(payment);
        }
      }
      this.createForm();
    } else {
      setTimeout(() => {
        this.addSpinner();
        this.defaultPetPayment();
      }, 250);
    }
  }

  isPaymentInfo() {
    return this.paymentList && this.paymentList.length && this.policyDetailsList && this.policyDetailsList.length;
  }

  getCardType(cardNumber: string) {
    const visaPattern = new RegExp(this.cardPatterns.visa);
    const amexPattern = new RegExp(this.cardPatterns.americanExpress);
    const discoverPattern = new RegExp(this.cardPatterns.discover);
    const masterCardPattern = new RegExp(this.cardPatterns.masterCard);

    let cardType = '';

    if (cardNumber.match(visaPattern)) {
      cardType = 'VI';
    } else if (cardNumber.match(amexPattern)) {
      cardType = 'AX';
    } else if (cardNumber.match(discoverPattern)) {
      cardType = 'DI';
    } else if (cardNumber.match(masterCardPattern)) {
      cardType = 'MC';
    }
    return cardType;
  }

  getPaymentsList() {
    this.addSpinner();
    this.billingPaymentService.getPaymentInfoList()
    .pipe(finalize(() => this.removeSpinner()))
    .subscribe({
      next: data => {
        if (data) {
          data.forEach(x => {
            if (x && x['payments']) {
              x['payments'].forEach(element => {
                const { homeTel, bizTel } = element;
                element.homeTel = (homeTel || '').replace(/[^0-9+]/g, '');
                element.bizTel = (bizTel || '').replace(/[^0-9+]/g, '');
                this.paymentList.push(element);
              });
            }
          }
          );
        }
        this.isPaymentListRetrieved = true;
      },
      error: () => this.removeSpinner(),
      complete: () => 
      {
        if(this.isPolicyPayroll)
        {
          this.billingFlag = true;
          this.removeSpinnerForPayroll();
        } 
        else 
        {
          this.removeSpinner();
        } 
      } 
    });
  }

  getMonthFromDate(expDate: string) {
    const month = expDate.split('/')[0];
    return parseInt(month, 10);
  }

  getPaymentFromPaymentList(policies: string[]) {
    const isAllSelected = this.isAllSelected(policies);
    const allSelectedPreviously = this.prevPolicySelected && this.isAllSelected(this.prevPolicySelected);
    return isAllSelected && !allSelectedPreviously

      ? this.paymentList[0]
      : this.paymentList && this.paymentList.reduce((acc, pL) => {
        if (pL.policyNumber === policies[0]) {
          acc = pL;
        }
        return acc;
      }, null);
  }

  getPolicyInfoList() {
    if (this.policyNumberFromRoute) {
      this.policyDetailsNewList = JSON.parse(sessionStorage.getItem('policyList'));
      if (this.policyDetailsNewList) {
        this.policyDetailsList = this.policyDetailsNewList.filter(policyDetail => {
          const status = policyDetail.policyStatus && policyDetail.policyStatus.toUpperCase();
          return status !== 'CANCELLED' && status !== 'EXPIRED';
        });
        this.isPolicyListRetrieved = true;
        const isPayrollpolicy = this.policyDetailsNewList.some(p => p.policyNumber === this.policyNumber[0] && p.policyType === "Group Payroll");
        this.isPolicyPayroll = isPayrollpolicy;
        this.addSpinner();
        if (isPayrollpolicy) {
          this.billingPaymentService.getRenPolicyDetailsList()
            .pipe(finalize(() => this.removeSpinner()))
            .subscribe({
              next: data => {
                this.policyInformation = data.policyDetailsList.filter(p => p.policyNumber === this.policyNumber[0]);
                this.policyInformation = this.policyInformation[0];
                this.setCurrentpolicyInfo();
              },
              error: () => this.removeSpinner(),
              complete: () => this.removeSpinner()
            });
        }
        else {
          this.billingPaymentService.getPolicyDetail(this.policyNumber[0])
            .pipe(finalize(() => this.removeSpinner()))
            .subscribe({
              next: data => {
                this.policyInformation = data;
                this.setCurrentpolicyInfo();
              },
              error: () => this.removeSpinner(),
              complete: () => this.removeSpinner()
            });
        }
      }
    }

  }

  setCurrentpolicyInfo() {
    const status = this.policyInformation.policyStatus && this.policyInformation.policyStatus.toUpperCase();
    this.isCancelled = status === 'CANCELLED';
    this.isExpired = status === 'EXPIRED';
    this.payMethod = this.policyInformation.payMethod;
    this.renewalNumber = this.policyInformation.renewalNumber;
    this.policyType = this.policyInformation.policyType;
    this.isLoadedWithGP = this.policyType === 'Group Payroll';
    this.policyExpiryDate = this.policyInformation.isPendingGroupRenewal ? this.policyInformation.groupRenewalExpiryDate : this.policyInformation.expirationDate;
    this.policyStatus = this.policyInformation && this.policyInformation.policyStatus && this.policyInformation.policyStatus.toUpperCase() === 'UNDER NOTICE';
    this.renewalEffectiveDate = this.policyInformation.groupRenewalEffectiveDate;
    this.renewalMidterm = this.policyInformation.isPendingGroupRenewal;
    const today = new Date();
    const renewDate = new Date(this.renewalEffectiveDate);
    this.pedFlag = renewDate >= today;
    if (this.isLoadedWithGP) {
      this.payrollId = this.policyInformation.payRollCode;
      this.nonPayrollId = this.policyInformation.nonPayRollCode;
      this.getAccountDetails();
      this.getEditPaymentMidterm();
    }
    this.getPolicyPaymentInfo();
  }

 

  getEditPaymentRequest(tokenNumber: string = '', accNumber: string = '') {
    const editPaymentInfo = <EditPaymentRequest>{};
    const paymentType: string = this.editPaymentInfoForm.value.paymentType;
    const cardNumber: string = accNumber || this.editPaymentInfoForm && this.editPaymentInfoForm.value.cardNumber;
    const accountNumber: string = this.editPaymentInfoForm && this.editPaymentInfoForm.value.accountNumber;
    const monthString = this.editPaymentInfoForm.value.expMonth < 10 ? '0' + this.editPaymentInfoForm.value.expMonth.toString() : this.editPaymentInfoForm.value.expMonth;
    this.payType = (paymentType.toUpperCase() === 'CREDIT CARD');
    this.maskedAcctNo = this.payType ? cardNumber.substr(cardNumber.length - 4) : accountNumber.substr(accountNumber.length - 4);
    this.payMethod = this.payType ? 'CC' : 'ACH';
    this.paymentType = this.payType ? 'Credit Card' : 'Debit Card';
    this.acctNo = this.payType ? `************${this.getLast4(cardNumber)}` : `***************${this.getLast4(this.editPaymentInfoForm.value.accountNumber)}`;
    this.abaNo = `*****${this.getLast4(this.editPaymentInfoForm.value.routingNumber)}`;
    editPaymentInfo.policyNumber = this.policyNumber[0];
    editPaymentInfo.policyRenewNumber = this.renewalNumber;
    editPaymentInfo.payMethod = this.payType ? 'CC' : 'ACH';
    this.payMethodCode = this.payType ? 'ID' : 'IA';
    editPaymentInfo.pmtPlan = this.editPaymentInfoForm.value.frequency === 'full' ? 'Y' : 'N';
    editPaymentInfo.acctType = this.payType ? this.getCardType(this.editPaymentInfoForm.value.cardNumber) : this.editPaymentInfoForm.value.accountType;
    editPaymentInfo.abaNo = this.editPaymentInfoForm.value.routingNumber;
    editPaymentInfo.acctNo = this.payType ? cardNumber : this.editPaymentInfoForm.value.accountNumber;
    editPaymentInfo.cardExpDate = this.payType ? this.editPaymentInfoForm.value.expYear + monthString : '';
    editPaymentInfo.tokenNo = this.payType ? tokenNumber : '';
    editPaymentInfo.firstName = this.accountInfo.firstName;
    editPaymentInfo.lastName = this.accountInfo.lastName;
    editPaymentInfo.middleName = this.accountInfo.middleName;
    editPaymentInfo.address1 = this.accountInfo.address1;
    editPaymentInfo.address2 = this.accountInfo.address2;
    editPaymentInfo.city = this.accountInfo.city;
    editPaymentInfo.state = this.accountInfo.state;
    editPaymentInfo.zip = this.accountInfo.zipCode;
    editPaymentInfo.homePhone = this.accountInfo.homePhone;
    editPaymentInfo.bussPhone = this.accountInfo.workPhone;
    editPaymentInfo.userName = this.getUserId();
    editPaymentInfo.amount = 0;
    editPaymentInfo.nonPayrollCode = this.editPaymentInfoForm.value.stillEmployed ? this.nonPayrollId : '';
    editPaymentInfo.termIdentifier = this.termIdentifier;
    editPaymentInfo.maskedAcctNo = this.maskedAcctNo;
    editPaymentInfo.acceptanceFlag = 'Y';
    editPaymentInfo.source = 'PHP';
    if (editPaymentInfo.acctType == 'SAVINGS') {
      editPaymentInfo.acctType = 'SAVING';
    }
    this.editPaymentInfo = editPaymentInfo;
  }

  authorizePaymentChange() {
    this.displayProgressSpinner=1;
    this.createOteXmlPayload(this.applyFormDataforPR);
    if (this.isPriorPastDue) {
      const priorTermPaymentReq = JSON.parse(JSON.stringify(this.editPaymentInfo));
      priorTermPaymentReq.policyRenewNumber =(Number(this.renewalNumber)-1).toString();
      priorTermPaymentReq.amount = this.editPaymentResponse.priorPastdueamount;
      
      this.billingPaymentService.editPolicyPaymentInfo(priorTermPaymentReq).subscribe({
        next: data => {
          this.displayProgressSpinner=0;
          if (data.standardMessage.code === '00') {
            this.authorizePaymentCurrentTerm();

          } else {
            if (data.standardMessage && data.standardMessage.code === 'NC400') {
              this.modalContent = data.standardMessage.developerMessage;
            }
            this.updateErrorModal();
          }
        },
        error: () => {
          this.displayProgressSpinner=0;
          this.updateErrorModal();
        },
        complete: () => this.displayProgressSpinner=0
      });
    }
    else {
      this.authorizePaymentCurrentTerm();
    }
  }

  authorizePaymentCurrentTerm() {
    this.billingPaymentService.editPaymentInfo(this.editPaymentInfo).subscribe({
      next: data => {
        this.displayProgressSpinner=0;
        if (data.standardMessage.code === '00') {
          this.updateSessionStoragePolicy(this.editPaymentInfo.policyNumber);
          this.achConfirmationModal();

        } else {
          if (data.standardMessage && data.standardMessage.code === 'NC400') {
            this.modalContent = data.standardMessage.developerMessage;
          }
          this.updateErrorModal();
        }
      },
      error: () => {
        this.displayProgressSpinner=0;
        this.updateErrorModal();
      },
      complete: () => this.displayProgressSpinner=0
    });
  }

  achConfirmationModal() {
    this.commonService.setAchConfirmationModal({
      header: 'We have successfully updated your payment information. Please allow up to 24 hours for changes to be reflected on your Nationwide Pet Account Access Page.'
    }).subscribe({
      next: () => this.goBack()
    });
  }

  submitPayrolltoDirect() {
    if (this.payType) {
      this.cardProfiles();
    } else {
      this.authorizePaymentChange();
    }
  }

  updateSessionStoragePolicy(policyNo: any) {
    this.billingPaymentService.getPolicyDetail(policyNo).subscribe({
      next: response => {
        this.displayProgressSpinner=0;
        if (response) {
          const policyDetailsSSList = JSON.parse(sessionStorage.getItem('policyList'));
          if (policyDetailsSSList && policyDetailsSSList.length != 0) {
            policyDetailsSSList.filter(policy => {
              if (policy.policyNumber == response.policyNumber) {
                policy.policyStatus = response.policyStatus;
                policy.premiumInstallmentAmount = response.premiumInstallmentAmount;
                policy.policyType = response.policyType;
                policy.isPendingGroupRenewal = response.isPendingGroupRenewal;
                policy.renewalStatus = response.renewalStatus;
                policy.expirationDate = response.expirationDate;
                policy.effectiveDate = response.effectiveDate;
              }
            });
            sessionStorage.setItem('policyList', JSON.stringify(policyDetailsSSList));
          }
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const dataServiceSSList = JSON.parse(sessionStorage.getItem('dataService'));
          if (dataServiceSSList && dataServiceSSList.myPets && dataServiceSSList.myPets.length != 0) {
            dataServiceSSList.myPets.filter(policy => {
              if (policy.policyNumber == response.policyNumber) {
                policy.policyStatus = response.policyStatus;
                policy.premiumInstallmentAmount = response.premiumInstallmentAmount;
                policy.policyType = response.policyType;
                policy.isPendingGroupRenewal = response.isPendingGroupRenewal;
                policy.isPendingRenewal = response.isPendingRenewal;
                policy.renewalStatus = response.renewalStatus;
                policy.expirationDate = response.expirationDate;
                policy.effectiveDate = response.effectiveDate;
                policy.deductible = response.deductible;
                policy.entireBalance = response.entireBalance;
                policy.payMethod = response.payMethod;
                policy.numofPayTerms = response.numofPayTerms;
                policy.pastDue = response.pastDue;
                policy.pastDueAmount = response.pastDueAmount;
              }
            });
            sessionStorage.setItem('dataService', JSON.stringify(dataServiceSSList));
          }
        }
        const dataServiceSSList = JSON.parse(sessionStorage.getItem('dataService'));
          if (dataServiceSSList && dataServiceSSList.myPets && dataServiceSSList.myPets.length != 0) {
            dataServiceSSList.myPets.filter(policy => {
              if (policy.policyNumber == response.policyNumber) {
                policy.policyStatus = response.policyStatus;
                policy.premiumInstallmentAmount = response.premiumInstallmentAmount;
                policy.policyType = response.policyType;
                policy.isPendingGroupRenewal = response.isPendingGroupRenewal;
                policy.isPendingRenewal = response.isPendingRenewal;
                policy.renewalStatus = response.renewalStatus;
                policy.expirationDate = response.expirationDate;
                policy.effectiveDate = response.effectiveDate;
                policy.deductible = response.deductible;
                policy.entireBalance = response.entireBalance;
                policy.payMethod = response.payMethod;
                policy.numofPayTerms = response.numofPayTerms;
                policy.pastDue = response.pastDue;
                policy.pastDueAmount = response.pastDueAmount;
              }
            });
            sessionStorage.setItem('dataService', JSON.stringify(dataServiceSSList));
          }
      },
      error: () => this.removeSpinner(),
      complete: () => this.removeSpinner()
    });
  }
  getEditPaymentMidterm() {
    const paymentMidtermRequest = <EditPaymentMidtermRequest>{};

    paymentMidtermRequest.payMethod = this.payMethod === 'Check' ? 'IA' : 'ID';
    paymentMidtermRequest.policyNumber = this.policyNumber[0];
    paymentMidtermRequest.policyRenewalNumber = this.renewalNumber;
    const sessionPolicyDetails = JSON.parse(sessionStorage.getItem("policyList"));

    let detailsofPolicy = sessionPolicyDetails.filter(policyDetail => {
      return policyDetail.policyNumber == paymentMidtermRequest.policyNumber;
    });

    if(Object.keys(detailsofPolicy[0].billBreakDown).length === 0 || detailsofPolicy[0].billBreakDown.amount === null)
    {
      this.installmentFee = '2.00';
    }
    else
    {
      this.installmentFee = detailsofPolicy[0].billBreakDown.amount;
    }
    this.addSpinner();
    if (this.renewalMidterm) {
      this.billingPaymentService.editPaymentRenewal(paymentMidtermRequest)
      .pipe(finalize(() => this.removeSpinner()))
      .subscribe({next:data => {
        this.editPaymentResponse = data;
        const today = new Date();
        const firstPayBillDueDate = new Date(this.editPaymentResponse.firstpaybillduedate);
        this.dateFlag = firstPayBillDueDate <= today;
        this.dateFlagForPIF = firstPayBillDueDate <= today;
        this.boxPifPayment = Number(this.editPaymentResponse.pastdueamountcurr) + Number(this.editPaymentResponse.pifAmountren);
        this.installmentAmount = Number(this.editPaymentResponse.pastdueamountcurr)+ Number(this.editPaymentResponse.downpaymentren);
        this.installmentPlan = 'Monthly';
      },
      error: () => this.removeSpinner(),
      complete:() =>
      {
        if(this.isPolicyPayroll)
        {
          this.midTermRenewalFlag = true;
          this.removeSpinnerForPayroll();
        } 
        else 
        {
          this.removeSpinner();
        } 
      }
    });
    } else {
      this.addSpinner();
      this.billingPaymentService.editPaymentMidterm(paymentMidtermRequest)
      .pipe(finalize(() => this.removeSpinner()))
      .subscribe({next:data => {
        this.editPaymentResponse = data;
        const priorpastdueVal = this.editPaymentResponse.priorPastdueamount?this.editPaymentResponse.priorPastdueamount.substring(1):'';
        if(priorpastdueVal){
          this.isPriorPastDue = Number(priorpastdueVal)>0;
        }
        this.installmentAmount = Number(this.editPaymentResponse.totalDueForMonth);
        this.installmentPlan = 'Monthly';
      },
      error: () => this.removeSpinner(),
      complete:() =>
      {
        if(this.isPolicyPayroll)
        {
          this.midTermRenewalFlag = true;
          this.removeSpinnerForPayroll();
        } 
        else 
        {
          this.removeSpinner();
        } 
      }
    });
    }
}

  navigateToEditPayment() {
    this.showConfirmation = false;
    this.isTermsCondition = false;
  }

  onChangePaymentPlan(planValue) {
    this.installmentPlan = planValue;
    const monthlyAmountRenewal = Number(this.editPaymentResponse.pastdueamountcurr)+ Number(this.editPaymentResponse.downpaymentren);
    const monthlyAmount = this.renewalMidterm ? monthlyAmountRenewal : this.editPaymentResponse.totalDueForMonth;
    const annualAmount = this.renewalMidterm ? this.editPaymentResponse.totalDueForAnnualRenewal : this.editPaymentResponse.totalDueForAnnual;
    this.installmentAmount = (planValue === 'Monthly') ? monthlyAmount : annualAmount;
  }

  getPolicyPaymentInfo() {
    this.addSpinner();
    this.billingPaymentService.getPaymentInfo(this.policyNumber[0], Number(this.renewalNumber))
      .pipe(finalize(() => this.removeSpinner()))
      .subscribe({
        next: data => {
          this.addSpinner();
          this.paymentInfo = data['paymentInfo'];
          this.termIdentifier = this.paymentInfo.termIdentifier;
        },
        error: () => this.removeSpinner(),
        complete: () => {
          if (this.isPolicyPayroll) {
            this.renewalBillingFlag = true;
            this.removeSpinnerForPayroll();
          }
          else {
            this.removeSpinner();
          }
        }
      });
  }

  removeSpinnerForPayroll()
  {
    if(this.billingFlag && this.midTermRenewalFlag && this.renewalBillingFlag)
    {
      this.removeSpinner();
    }
  }

  getOrganizationInfo() {
    const payrollId = this.payrollId;
    const nonPayrollId = this.nonPayrollId;
    if (payrollId) {
      this.billingPaymentService.getOrganizationDetails(payrollId, '').subscribe(
        (message) => {
          this.empName = message.nameofGroup;
        });
    } else if (nonPayrollId) {
      this.billingPaymentService.getOrganizationDetails('', nonPayrollId).subscribe(
        (message) => {
          this.empName = message.nameofGroup;
        });
    }
  }

  getAccountDetails() {
    this.addSpinner();
    this.billingPaymentService.getCompanyDetail({
      companyType: 'Employer',
      payrollCode: this.payrollId,
      nonPayrollCode: this.nonPayrollId
    }).pipe(finalize(() => this.removeSpinner()))
    .subscribe((message) => {
      this.empName = message.name;
    });
  }

  getNext10Years(): number[] {
    const today = new Date();
    const currYr = today.getFullYear();
    const next10Years = [];
    for (let i = 0; i <= 10; i++) {
      next10Years.push(currYr + i);
    }
    return next10Years;
  }

  getUserId() {
    const userId = sessionStorage.getItem('userId');
    this.userId = sessionStorage.getItem('userId');
    return userId;
  }

  goToAccount() {
    this.router.navigateByUrl(this.routes.account);
  }

  isAllSelected(policies: string[]) {
    return policies.reduce((acc, policy) => {
      if (policy === 'all') {
        acc = true;
      }
      return acc;
    }, false);
  }

  isCredit(): boolean {
    return this.editPaymentInfoForm && this.editPaymentInfoForm.value.paymentType === 'Credit card';
  }

  isAch(): boolean {
    return this.editPaymentInfoForm && this.editPaymentInfoForm.value.paymentType.toUpperCase() === 'BANK ACCOUNT';
  }

  numbersOnly(event: KeyboardEvent) {
    return (event.key >= '0' && event.key <= '9') || event.key === ' ' || event.key === 'backspace';
  }

  save() {
    if (this.canSubmit()) {
      const appliedFormData = this.applyFormData();
      if (this.policyType === 'Group Payroll') {
        if (this.editPaymentInfoForm.value.paymentType.toUpperCase() === 'CREDIT CARD') {
          this.showConfirmation = true;
          this.getEditPaymentRequest();
          this.applyFormDataforPR = appliedFormData;
        } else {
          this.checkABAPayroll(appliedFormData);
          this.applyFormDataforPR = appliedFormData;
        }

      } else {
        this.updatePaymentInfo(appliedFormData);
      }
      this.setGTMEvents();
    }
  }

  getTermsAndConditionModel() {
    const today = moment(new Date()).format('LL');
    let isCreditcard = '';
    if (this.editPaymentInfoForm.value.paymentType == 'Credit card') {
      isCreditcard = 'credit card';
    } else {
      isCreditcard = 'debit card';
    }
    let contentType = '';
    const isPayrollpolicy = this.policyDetailsNewList.some(p => p.policyNumber === this.policyNumber[0] && p.policyType === 'Group Payroll');
    if (isPayrollpolicy) {
      contentType = this.installmentPlan;
    } else {
      contentType = this.getTermAndConditionContentType();
    }
    let achContent = this.achConstants.termsAndCondition.content;
    let achSubContent = '';
    if (contentType == 'Monthly') {
      if (isCreditcard == 'credit card') {
        achContent = this.achConstants.termsAndCondition.recurringBankCardContent;
      } else {
        achContent = this.achConstants.termsAndCondition.recurringACHContent;
      }

    } else if (contentType == 'Annual') {
      if (isCreditcard == 'credit card') {
        achContent = this.achConstants.termsAndCondition.oneTimeBankCardContent;
      } else {
        achContent = this.achConstants.termsAndCondition.oneTimeACHContent;
      }

    } else if (contentType == 'General') {
      if (isCreditcard == 'credit card') {
        achContent = this.achConstants.termsAndCondition.recurringBankCardContent;
        achSubContent = this.achConstants.termsAndCondition.oneTimeBankCardContent;
      } else {
        achContent = this.achConstants.termsAndCondition.recurringACHContent;
        achSubContent = this.achConstants.termsAndCondition.oneTimeACHContent;
      }
    }
    achContent = achContent.replace('TODAY', today).replace('CARDTYPE', isCreditcard);
    if (achSubContent != '') {
      achSubContent = achSubContent.replace('TODAY', today).replace('CARDTYPE', isCreditcard);
    }
    this.commonService.setTermsDialogModal({
      header: this.achConstants.termsAndCondition.header,
      content: achContent,
      subContent: achSubContent
    });
  }

  setGTMEvents() {
    const event = {
      event: 'Portal Data Layer',
      eventCategory: 'Button Click',
      eventAction: 'Edit Payment Information Save',
      eventLabel: 'Edit Payment Information Save Button',
      eventValue: 'Accounts: Edit Payment Information Save',
      userId: sessionStorage.getItem('userId'),
    };
    this.fullstoryService.recordCustomEvent(event.event, { message: event });
  }

  checkABA(appliedFormData) {
    this.sendUpdatePaymentRequest(appliedFormData);
  }
  checkABAPayroll(appliedFormData) {
    this.showConfirmation = true;
    this.getEditPaymentRequest();
  }

  cardProfile(appliedFormData: { paymentInfoList: PaymentInfo[] }) {
    const paymentInfo = appliedFormData.paymentInfoList[0];
    const ccAccountNumber = paymentInfo.acctNo;
    const cardLength = ccAccountNumber && ccAccountNumber.length;
    if (cardLength) {
      this.lastFour = ccAccountNumber.slice(cardLength - 4, cardLength);
    }
    this.billingPaymentService
      .cardProfiles({
        customerName: `${paymentInfo.firstName} ${paymentInfo.lastName}`,
        customerAddress1: paymentInfo.address1,
        customerAddress2: paymentInfo.address2,
        customerCity: paymentInfo.city,
        customerZIP: paymentInfo.zip,
        customerEmail: this.accountInfo.email,
        customerPhone: paymentInfo.homeTel || paymentInfo.bizTel,
        customerAccountType: 'CC',
        accountNumber: paymentInfo.acctNo,
        expirationDate: paymentInfo.cardExpDate
      })
      .subscribe({
        next: response => {
          if (response.status === '0') {
            appliedFormData.paymentInfoList = appliedFormData.paymentInfoList.map(payment => ({
                ...payment,
                maskedAcctNo: String(response.accountNumber),
                acctNo: String(response.accountNumber),
                securityCode: String(response.customerRefNum)
              }));
            this.sendUpdatePaymentRequest(appliedFormData);
          } else {
            this.displayProgressSpinner = 0,
            this.commonService.setValidationErrorModal('Invalid Credit Card.');
          }
        },
        error:() => {
          this.displayProgressSpinner=0,
          this.commonService.setDialogModal({
            title: 'Error',
            header: 'An error occurred. Please try again.',
            content: '',
          });
        } 
      });
  }

  cardProfiles() {
    const paymentInfo = this.editPaymentInfoForm.value;
    const monthString = this.editPaymentInfoForm.value.expMonth < 10 ? '0' + this.editPaymentInfoForm.value.expMonth.toString() : this.editPaymentInfoForm.value.expMonth;
    const ccAccountNumber = this.editPaymentInfoForm.value.cardNumber;
    const cardLength = ccAccountNumber && ccAccountNumber.length;
    if (cardLength) {
      this.lastFour = ccAccountNumber.slice(cardLength - 4, cardLength);
    }
    this.displayProgressSpinner=1;
    this.billingPaymentService
      .cardProfiles({
        customerName: `${this.accountInfo.firstName} ${this.accountInfo.lastName}`,
        customerAddress1: this.accountInfo.address1,
        customerAddress2: this.accountInfo.address2,
        customerCity: this.accountInfo.city,
        customerZIP: this.accountInfo.zipCode,
        customerEmail: this.accountInfo.email,
        customerPhone: this.accountInfo.homePhone || paymentInfo.workPhone,
        customerAccountType: 'CC',
        accountNumber: this.editPaymentInfoForm.value.cardNumber,
        expirationDate: this.editPaymentInfoForm.value.expYear + monthString
      })
      .subscribe({
        next: response => {
          if (response.status === '0') {
            this.editPaymentInfo.acctNo = String(response.accountNumber);
            this.editPaymentInfo.tokenNo = String(response.customerRefNum);
            this.authorizePaymentChange();
          } else {
            this.commonService.setValidationErrorModal('Invalid Credit Card.');
          }
        },
        error:() => {
          this.displayProgressSpinner=0,
          this.commonService.setDialogModal({
            title: 'Error',
            header: 'An error occurred. Please try again.',
            content: '',
          });
        },
         complete: () => {this.displayProgressSpinner=0} 
      });
  }

  updatePaymentInfo(appliedFormData) {
    if (this.policyNumber) {
      const paymentType = this.editPaymentInfoForm.value.paymentType;
      this.displayProgressSpinner = 1;
      if (paymentType && paymentType.toUpperCase() === 'BANK ACCOUNT' && this.editPaymentInfoForm.controls.routingNumber.dirty) {
        this.checkABA(appliedFormData);
      } else {
        this.cardProfile(appliedFormData);
      }
    }
  }

  updateErrorModal() {
    this.commonService.setDialogModal({
      title: 'Error',
      header: this.modalContent ? this.modalContent : 'Error updating payment information.',
      content: '',
    });
  }

  authorizePayment(appliedFormData: EditPaymentRequest) {
    this.displayProgressSpinner=1;

    this.billingPaymentService.editPaymentInfo(appliedFormData).subscribe({
      next: data => {
        if (data.standardMessage) {
          if (data.standardMessage.code === 'NC400') {
            this.modalContent = data.standardMessage.developerMessage;
          }
          this.updateErrorModal();
        } else {
          this.commonService
            .setConfirmationModal({
              header: 'We have successfully updated your payment information.'
            })
            .subscribe({
              next: () => this.goBack()
            });
        }
      },
      error: () => {
        this.abaError = true;
        this.updateErrorModal();
      },
      complete: () => this.displayProgressSpinner=0
    });
  }

  applyPaymentAuthRequest(appliedFormData) {
    const paymentAuthList: any = [];
    this.createOteXmlPayload(appliedFormData);
    appliedFormData.paymentInfoList.forEach(paymentInfo => {
      let paymentType = '';
      let accountNo = '';
      let ccNo = '';
      if (paymentInfo.payMethod != 'CC') {
        paymentType = this.setACHPayMethod(paymentInfo.acctType);
        accountNo = paymentInfo.acctNo;
      } else {
        paymentType = this.setACHPayMethod(paymentInfo.acctType);
        ccNo = paymentInfo.maskedAcctNo;
      }
      const paymentAuthObj: any = {
        'policyNumber': paymentInfo.policyNumber,
        'termNumber': paymentInfo.renewal,
        'lastFourDigitsCCNo': ccNo,
        'achAccount': accountNo,
        paymentType,
        'authInd': 'Y',
        'sourceSystem': 'Portal'
      };
      paymentAuthList.push(paymentAuthObj);
    });
    return paymentAuthList;
  }

  updatePaymentAuthErrorModal() {
    this.commonService.setDialogModal({
      title: 'Error',
      header: this.modalContent ? this.modalContent : 'Error updating payment Authorization information.',
      content: '',
    });
  }

  sendUpdatePaymentRequest(appliedFormData: { paymentInfoList: PaymentInfo[] }) {
    this.createOteXmlPayload(appliedFormData);
    this.billingPaymentService.updatePaymentDetails(appliedFormData).subscribe({
      next: data => {
        if (data.some(x => x.standardMessage)) {
          if (data.some(x => x.standardMessage['code'] === 'NC400')) {
            this.modalContent = data.find(x => x.standardMessage['code'] === 'NC400').standardMessage['developerMessage'];
          }
          this.updateErrorModal();
        } else {
          this.commonService.setAchConfirmationModal({
            header: 'We have successfully updated your payment information.'
          }).subscribe({
            next: () => this.goBack()
          });
        }
      },
      error: () => {
        this.displayProgressSpinner=0;
        this.updateErrorModal();
      },
      complete: () => this.displayProgressSpinner=0
    });
  }

  setPaymentType(type) {
    switch (type) {
      case 'ID':
        return 'Credit Card';
      case 'IA':
        return 'Debit Card';
      case 'IC':
        return 'Check';
      case 'A1':
        return 'Check Credit';
      case 'A2':
        return 'Check Debit';
    }
  }

  setPayMethod(type) {
    let res = '';
    switch (type) {
      case 'AX':
        res = 'American Express';
        break;
      case 'DI':
        res = 'Discover';
        break;
      case 'MC':
        res = 'Mastercard';
        break;
      case 'VI':
        res = 'Visa';
        break;
      case 'CHECKING':
        res = 'Checking';
        break;
      case 'SAVING':
        res = 'Savings';
        break;
    }
    return res;
  }

  setExpirationDate(card) {
    return card && card.substring(4, card.length) + '/' + card.substring(0, 4) || '';
  }

  setACHPayMethod(type) {
    let res = '';
    switch (type) {
      case 'AX':
        res = 'AMEX';
        break;
      case 'DI':
        res = 'DISC';
        break;
      case 'MC':
        res = 'Master';
        break;
      case 'VI':
        res = 'VISA';
        break;
      case 'CHECKING':
        res = 'CHECKING';
        break;
      case 'SAVING':
        res = 'SAVINGS';
        break;
    }
    return res;
  }


  paymentTypeChange(input) {
    this.isTermsCondition = false;
  }

  createOteXmlPayload(appliedFormData) {

    const Recipient: any = {};
    const ach: any = {};
    let documentContentXml: any = '';
    this.oteDocumentService.policyNumberList = [];
    const sessionPolicyList = JSON.parse(sessionStorage.getItem('dataService'));
    let isAnnual = true;
    const isPayrollpolicy = this.policyDetailsNewList.some(p => p.policyNumber === this.policyNumber[0] && p.policyType === 'Group Payroll');
    appliedFormData.paymentInfoList.forEach(paymentInfo => {
      if(this.isGroupPayroll){
        paymentInfo.policyNumber = this.editPaymentInfoForm.value.policyNumber[0];
        }
      const policyDetailsList = sessionPolicyList.myPets.filter(policyDetail => policyDetail.policyNumber == paymentInfo.policyNumber);
      if (isPayrollpolicy) {
        if (this.installmentPlan == 'Annual') {
          isAnnual = true;
        } else {
          isAnnual = false;
        }
      } else {
        if (policyDetailsList[0].numofPayTerms > 1) {
          isAnnual = false;
        } else {
          isAnnual = true;
        }
      }
      let documentName = '';
      if (paymentInfo.payMethod == 'CC') {
        if (!isAnnual) {
          documentName = 'POTRBC';
        } else {
          documentName = 'POTBC';
        }
      } else {
        if (!isAnnual) {
          documentName = 'POTRACH';
        } else {
          documentName = 'POTACH';
        }
      }

      const Document = [
        {
          '$': {
            Name: documentName
          }
        }
      ];
      const PrintData: any = {};
      const currentPolicy: any = this.policyDetailsNewList.filter(policyDetail => policyDetail.policyNumber == paymentInfo.policyNumber);
      const effectiveDate = moment(currentPolicy[0].effectiveDate).add(1, 'days').format('LL');
      const policyEffectiveDate = moment(currentPolicy[0].effectiveDate).add(30, 'days').format('LL');
      PrintData.Document = Document;
      PrintData.PolicyNumber = paymentInfo.policyNumber;
      Recipient.Name = paymentInfo.firstName + ' ' + paymentInfo.lastName;
      PrintData.Recipient = Recipient;
      ach.IssueDate = effectiveDate ? effectiveDate : '';
      ach.ACHPolicyEffectiveDate = policyEffectiveDate ? policyEffectiveDate : '';
      ach.PortalURL = this.achConstants.xmlFormat.portalURL;
      ach.Authdays = this.achConstants.xmlFormat.authdays;
      ach.CallNumber = this.achConstants.xmlFormat.callNumber;
      ach.ValidTime = this.achConstants.xmlFormat.validTime;
      if (paymentInfo.payMethod == 'CC') {
        ach.CardType = this.setPayMethod(paymentInfo.acctType);
        ach.LastFour = paymentInfo.maskedAcctNo;
      } else {
        ach.BankRoutingNumber = '*****' + this.getLast4(paymentInfo?.abaNo);
        ach.BankAccountNumber = '******' + this.getLast4(paymentInfo.acctNo);
        ach.BankAccountType = this.setPayMethod(paymentInfo.acctType);
      }
      this.oteDocumentService.policyNumberList.push(paymentInfo.policyNumber);
      PrintData.ACH = ach;
      const builder = new xml2js.Builder({ headless: true, explicitRoot: false, rootName: 'PrintData' });
      documentContentXml = documentContentXml + builder.buildObject(PrintData);
    });

    const base64EncodedXml = btoa(documentContentXml);
    const oteRequest = {
      'pubName': this.achConstants.oteRequest.publishName,
      'fileName': this.achConstants.oteRequest.fileName,
      'referenceArea': this.achConstants.oteRequest.referenceArea,
      'driverFile': base64EncodedXml
    };
    this.oteDocumentService.requestJsonObject = oteRequest;

  }

  getTermAndConditionContentType() {
    const sessionPolicyList = JSON.parse(sessionStorage.getItem('dataService'));
    if (this.policyNumber.length == 1 && this.policyNumber[0] != 'all') {
      const policyDetailsList = sessionPolicyList.myPets.filter(policyDetail => policyDetail.policyNumber == this.policyNumber[0]);
      if (policyDetailsList[0].numofPayTerms > 1) {
        return 'Monthly';
      } else {
        return 'Annual';
      }
    } else if (this.policyNumber.length == 1 && this.policyNumber[0] == 'all') {
      let annualCount = 0;
      let monthlyCount = 0;
      const policyDetailsList = sessionPolicyList.myPets.filter(policyDetail => {
        const status = policyDetail.policyStatus && policyDetail.policyStatus.toUpperCase();
        if (status !== 'CANCELLED' && status !== 'EXPIRED') {
          if (policyDetail.numofPayTerms > 1) {
            monthlyCount++;
          } else {
            annualCount++;
          }
        }
        return status !== 'CANCELLED' && status !== 'EXPIRED';
      });
      if (policyDetailsList.length == monthlyCount) {
        return 'Monthly';
      } else if (policyDetailsList.length == annualCount) {
        return 'Annual';
      } else {
        return 'General';
      }
    } else if (this.policyNumber.length > 1) {
      let annualCount = 0;
      let monthlyCount = 0;
      this.policyNumber.filter(policyNumber => {
        const policyDetailsList = sessionPolicyList.myPets.filter(policyDetail => {
          const status = policyDetail.policyStatus && policyDetail.policyStatus.toUpperCase();
          if (policyDetail.policyNumber == policyNumber) {
            if (policyDetail.numofPayTerms > 1) {
              monthlyCount++;
            } else {
              annualCount++;
            }
          }
          return policyDetail.policyNumber == policyNumber;
        });
      });
      this.policyNumber.filter(policy => policy !== 'all');
      if (this.policyNumber.length == monthlyCount) {
        return 'Monthly';
      } else if (this.policyNumber.length == annualCount) {
        return 'Annual';
      } else {
        return 'General';
      }
    }
  }

  addSpinner(){
    this.displayProgressSpinner=1;
  }

  removeSpinner(){
    this.displayProgressSpinner=0;
  }
}
