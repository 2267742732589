import { Component, Input, OnInit } from '@angular/core';
import {PetIdPdf} from './pet-id-pdf';
import { DatePipe } from '@angular/common';
import {HYPERLINKSCONSTANTS} from '../../../shared/constants/hyperlinks-constants';
import { ContentfulService } from '../../../shared/services/contentful.service';
import CONTENTFULIDS from '../../../shared/constants/contentful-ids';

@Component({
  selector: 'app-insurance-id-card',
  templateUrl: './insurance-id-card.component.html',
  styleUrls: ['./insurance-id-card.component.scss']
})
export class InsuranceIdCardComponent implements OnInit {
  @Input() public policyDetails: any;
  @Input() public memberName: any;
  @Input() public nwInsuranceCardHeaderLogo: any;
  @Input() public cobrandingInsuranceCardHeaderLogo: any;
  public hyperlinkConstants = HYPERLINKSCONSTANTS;
  public petRxExpressLogoUrl = '';
  public contentfulIds = CONTENTFULIDS;

  constructor(private contentfulService: ContentfulService) {
    this.getMarketingLogo();
   }

  ngOnInit() {
    
  }

  // async getNWHeaderLogo() 
  // {
  //   const res = await this.contentfulService.getAsset(this.contentfulIds.assets.nationWideHeaderLogo);
  //   if (res) 
  //   {
  //     this.headerLogoUrl = res.file.url;
  //   }
  // }

  async getMarketingLogo() 
  {
    const res = await this.contentfulService.getAsset(this.contentfulIds.assets.petRxExpressOneLineLogo);
    if (res) 
    {
      this.petRxExpressLogoUrl = res.file.url;
    }
  }


  saveIdCard() {
    const policy = this.policyDetails;
    const pipe = new DatePipe('en-US');
    const expDate = policy.expirationDate ? new Date(policy.expirationDate) : '';
    const dob = policy.pet.dateofbirth ?  new Date(policy.pet.dateofbirth) : '';
    const expirationDate = expDate ? pipe.transform(expDate, 'M/d/yyyy') : '';
    const dateofbirth = dob ? pipe.transform(dob, 'M/d/yyyy') : '';
    const headerLogoUrl = policy.cobrandingStatus ? this.cobrandingInsuranceCardHeaderLogo : this.nwInsuranceCardHeaderLogo;
    new PetIdPdf({
      petName: policy.pet.name,
      policyNumber: policy.policyNumber,
      coverage: policy.coverage,
      policyExpirationDate: expirationDate,
      status: policy.policyStatus,
      memberName: this.memberName,
      relationShip: '01',
      insuranceDob: dateofbirth,
      bin: '023120',
      pcn: 'MED1',
      group: 'NWIDE01',
      effectiveUntil: expirationDate,
      petRxExpressLogoUrl: this.petRxExpressLogoUrl,
      headerLogoUrl: headerLogoUrl
    }).save();
  }

}
